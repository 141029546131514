@charset "utf-8";

$point-sp320: 320px;
$point-sp: 779px;
$point-tb: 780px;
$point-pc: 980px;
$point-wide: 1440px;


//@include sp320{};
@mixin sp320 {
  @media screen and (max-width: $point-sp320) {
    @content;
  }
}

//@include sp{};
@mixin sp {
  @media screen and (max-width: $point-sp) {
    @content;
  }
}

//@include tb{};
@mixin tb {
  @media screen and (min-width: $point-tb) {
    @content;
  }
}

//@include pc{};
@mixin pc {
  @media screen and (min-width: $point-pc) {
    @content;
  }
}

//@include wide{};
@mixin wide {
  @media screen and (min-width: $point-wide) {
    @content;
  }
}



//@include pcIE{};
@mixin pcIE {
  @media screen and (min-width: $point-pc) and (-ms-high-contrast:none) {
    @content;
  }
}

//@include IE{};
@mixin IE {
  @media screen and (-ms-high-contrast:none) {
    @content;
  }
}

//@include onlyTB{};
@mixin onlyTB {
  @media (min-width: $point-tb) and (max-width: $point-pc) {
    @content;
  }
}

//@include onlyPC{};
@mixin onlyPC {
  @media (min-width: $point-pc) and (max-width: $point-wide) {
    @content;
  }
}

//@include linkColor($color);
@mixin linkColor($color) {
  color: $color;
  &:hover,
  &:active,
  &:focus {
    color: lighten($color, 20%);
  }
}

//@include clearfix;
@mixin clearfix {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

// @include ov;
@mixin ov {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.7;
    img {
      background-color: rgba(#fff, 0.01);
    }
  }
}

//@include lhCrop(line-height/fontsize);

//.hoge{
//  @include lhCrop(80/40);
//  font-size: 40px;
//  line-height: (80/40);
//}

@mixin lhCrop($line-height) {
  &::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
    content: '';
  }
}
