@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-top {
  &__slider {
    position: relative;
    margin: 0 auto;
    @include pc {
      background: url(/img/top/slider_bg.jpg)repeat-x top center;
      background-size: auto 100%;
    }
    &Item {
      img {
        width: 100%;
      }
    }
    .swiper-container {
      max-width: 1440px;
    }
    .swiper-wrapper {
      transition-timing-function: cubic-bezier(.65, .05, .36, 1);
    }
    .swiper-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 1200px;
      z-index: 10;
    }
    .swiper-button-prev,
    .swiper-button-next {
      background: url(/img/common/slider_arrow.svg)no-repeat;
      background-size: 100% auto;
      height: 40px;
      transition: all 0.25s;
      width: 24px;
      &:hover {
        background: url(/img/common/slider_arrow_ov.svg)no-repeat;
        background-size: 100% auto;
      }
    }
    .swiper-button-prev {
      transform: rotate(180deg);
    }
  }
  &__head {
    margin-top: -11px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    @include pc {
      margin-top: -18px;
      margin-bottom: 60px;
    }
    .c-sec {
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        margin-bottom: 0;
        padding: 0 0 24px;
        @include pc {
          border-right: 1px solid #7f7f7f;
          min-height: 40px;
          padding: 0 4% 0 2.2%;
        }
        img {
          width: 136px;
          @include pc {
            width: 181px;
          }
        }
      }
    }
    &__productList {
      background: #fff;
      margin-bottom: 14px;
      padding: 28px 3% 0;
      position: relative;
      @include pc {
        display: flex;
        margin-bottom: 21px;
        padding: 48px 30px 30px 30px;
      }
      &:before,
      &:after {
        content: '';
        display: block;
        height: 25px;
        position: absolute;
        top: 11px;
        width: 9px;
        @include pc {
          height: 42px;
          top: 18px;
          width: 15px;
        }
      }
      &:before {
        content: '';
        background: url(/img/top/product_bg_sp.png)no-repeat;
        background-size: 100% auto;
        left: -9px;
        @include pc {
          background: url(/img/top/product_bg.png)no-repeat;
          background-size: 100% auto;
          left: -15px;
        }
      }
      &:after {
        content: '';
        background: url(/img/top/product_bg02_sp.png)no-repeat;
        background-size: 100% auto;
        right: -9px;
        @include pc {
          background: url(/img/top/product_bg02.png)no-repeat;
          background-size: 100% auto;
          right: -15px;
        }
      }
    }
    &__logoList {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @include pc {
        justify-content: flex-start;
        margin: 0 0 0 1%;
        width: 100%;
      }
      .p-top__head__logoItem {
        margin: 0 10px 10px;
        @include pc {
          margin: 6px 4.8%;
        }
        a {
          display: flex;
          align-items: center;
          &:hover {
            img {
              @include pc {
                transform: scale(1.05);
              }
            }
          }
        }
        img {
          max-height: 20px;
          max-width: 155px;
          @include pc {
            max-height: 28px;
            max-width: 172px;
            transition: all 0.2s;
          }
        }
      }
    }
    &__bunnerList {
      @include pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    &__bunnerItem {
      border: 1px solid #bfbfbf;
      margin-bottom: 15px;
      @include pc {
        margin-bottom: 0;
        overflow: hidden;
        width: calc(50% - 16px);
        &.-large {
          margin-top: 15px;
          order: 3;
          width: 100%;
          a {
            &:hover {
              img {
                @include pc {
                  transform: scale(1.025);
                }
              }
            }
          }
        }
      }
      a {
        &:hover {
          img {
            @include pc {
              transform: scale(1.05);
            }
          }
        }
      }
      img {
        transition: all 0.2s;
        width: 100%;
      }
    }
  }
  .l-login {
    margin-bottom: 28px;
    @include pc {
      display: none;
    }
  }
  &__recommend {
    padding-bottom: 30px;
    margin: 0 auto;
    @include pc {
      padding-bottom: 62px;
    }
    .c-sec {
      &__inner {
        position: relative;
        @include pc {
          margin: 0 auto;
          padding: 0 4.167%;
        }
      }
      &__title {
        margin-bottom: 0;
        padding-top: 32px;
        position: relative;
        &:after {
          content: '';
          background: #000;
          display: block;
          height: 1px;
          position: absolute;
          left: 50%;
          bottom: 17px;
          transform: translateX(-50%);
          width: 22px;
          @include pc {
            content: none;
          }
        }
        img {
          width: 238px;
          @include pc {
            width: 25%;
          }
        }
        &JP {
          margin-top: 6px;
          @include pc {
            margin-top: 10px;
          }
        }
      }
    }
    &Head {
      display: inline-block;
      margin: 0 auto 35px;
      padding: 0 14px 16px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      @include pc {
        border-right: 1px solid #000;
        margin: 0;
        padding: 14px 2.3% 19px 0;
        position: absolute;
        top: 43px;
        left: auto;
        right: 4.167%;
        text-align: right;
        transform: translateX(0);
      }
      &:before {
        content: '';
        background: #000;
        display: block;
        height: 10px;
        position: absolute;
        right: -1px;
        bottom: 0;
        width: 1px;
        @include pc {
          content: none;
        }
      }
      &:after {
        content: '';
        background: url(/img/top/recommend_line.svg)no-repeat;
        background-size: cover;
        display: block;
        height: 25px;
        position: absolute;
        right: -1px;
        bottom: -25px;
        width: 297px;
        @include pc {
          width: 100%;
        }
      }
      &__text {
        @include font-noto_sans_regular;
        @include fz(13);
        font-feature-settings: 'palt';
        letter-spacing: 0.18em;
        line-height: (19/13);
        @include pc {
          @include vw(15);
          line-height: (24/15);
        }
        span {
          color: #888;
          @include fz(11);
          letter-spacing: 0.16em;
          line-height: (23/11);
          @include pc {
            @include vw(11);
          }
        }
      }
    }
    &Slider {
      overflow: hidden;
      position: relative;
      &__inner {
        @include pc {
          width: calc(100% - 6%);
          margin-right: auto;
          margin-left: auto;
          overflow: visible;
          max-width: 1236px;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      background-color: #e60012;
      background-image: none;
      border-radius: 50%;
      height: 74px;
      transition: all 0.25s;
      width: 74px;
      @include pc {
        height: 100px;
        width: 100px;
      }
      &:after {
        content: '';
        background: url(/img/common/icon_arrow04.svg)no-repeat;
        background-size: 100% auto;
        height: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 11px;
      }
      &:hover {
        @include pc {
          transform: scale(1.3);
        }
      }
    }
    .swiper-button-prev {
      justify-content: flex-end;
      left: -38px;
      @include pc {
        left: calc(((100vw - 100%) / 2 + 50px) * -1);
      }
      &.swiper-button-disabled {
        opacity: 0;
      }
      &:after {
        transform: translateY(-50%) rotate(-180deg);
        right: 15px;
        @include pc {
          right: 28px;
        }
      }
    }
    .swiper-button-next {
      right: -38px;
      @include pc {
        right: calc(((100vw - 100%) / 2 + 50px) * -1);
      }
      &.swiper-button-disabled {
        opacity: 0;
      }
      &:after {
        left: 15px;
        @include pc {
          left: 28px;
        }
      }
    }
    &Item {
      margin: 0 auto;
      text-align: center;
      width: 80%;
      @include pc {
        padding: 0 16px;
        box-sizing: border-box;
        width: 418px;
      }
    }
    &Top {
      @include font-noto_sans_regular;
      background: #e60012;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      @include fz(11);
      font-feature-settings: 'palt';
      line-height: (18/13);
      letter-spacing: 0.16em;
      margin-bottom: 14px;
      padding: 8px 10px 7px;
      position: relative;
      @include pc {
        @include vw(13);
        line-height: (23/13);
        margin-bottom: 17px;
      }
      strong {
        @include font-noto_sans_black;
      }
      &:after {
        content: '';
        border-style: solid;
        border-width: 9px 5px 0 5px;
        border-color: #e60013 transparent transparent transparent;
        height: 0;
        position: absolute;
        left: 50%;
        bottom: -9px;
        transform: translateX(-50%);
        width: 0;
      }
      & + .c-product {
        margin-top: 0 !important;
      }
    }
    .c-product {
      margin-top: calc( (1.38462*11px) + (14px + 8px + 7px));
			@include sp{
				padding: 30px 16px 0;
				.c-tagList {
					margin-left: -2px;
				}
				.c-tag.-gray {
					letter-spacing: .1em;
				}
				.c-tag:last-child {
					margin-right: 0;
				}
			}
      @include onlyPC {
        margin-top: calc( (0.90278*1.76923vw) + (17px + 8px + 7px));
       }
      @include wide {
        margin-top: calc( (13*1.76923px) + (17px + 8px + 7px)) ;
      }
      &Item {
        &__title {
          position: relative;
        }
				&__column {
					@include sp{
						flex-basis: 60%;
						width: 60%;
						&.-single {
							flex-basis: 40%;
							width: 40%;
						}
					}
					&.-right {
						@include sp{
							flex-basis: 40%;
							width: 40%;
						}
					}
				}
      }
    }
    .c-btn {
      margin-left: auto;
    }
  }
  &__newItem {
    .c-sec {
      &__title {
        margin-bottom: 0;
        padding-bottom: 15px;
        @include pc {
          padding-bottom: 32px;
        }
        img {
          width: 186px;
          @include pc {
            width: 14.254%;
          }
        }
        &JP {
          margin-top: 6px;
          @include pc {
            margin-top: 4px;
          }
        }
      }
    }
    .swiper-container {
      &:not(.swiper-container-initialized) {
        .p-top__newItem__list {
          justify-content: center;
          @include pc {
            justify-content: flex-start;
          }
        }
      }
    }
    &__list {
      margin-bottom: 30px;
      @include pc {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        margin: 0 auto 64px;
        max-width: 1440px;
        padding: 0 3.15%;
      }
    }
    &__item {
      background: #f7f7f7;
      height: 196px;
      width: 80%;
      @include pc {
        height: auto;
        margin-left: 2.3%;
        overflow: hidden;
        width: 31.8%;
      }
      &:first-of-type {
        margin-left: 0;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        @include pc {
          height: 278px;
          position: relative;
        }
        &:hover {
          img {
            @include pc {
              transform: translate(-50%, -50%) scale(1.05);
            }
          }
        }
      }
      img {
        max-height: 196px;
        max-width: 100%;
        width: auto;
        @include pc {
          max-height: 100%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.2s;
          max-width: 100%;
          width: auto;
        }
      }
    }
    &__news {
      &List {
        .p-top__newItem__newsItem {
          &:nth-child(odd) {
            background: #fee5e0;
          }
          &:nth-child(even) {
            background: #fff2ef;
          }
        }
      }
      &Item {
        a {
          &:hover {
            .p-top__newItem__newsText {
              text-decoration: underline;
            }
          }
        }
      }
      &Head {
        display: block;
        padding: 18px 6% 33px;
        position: relative;
        @include pc {
          display: flex;
          padding: 22px 2.5% 20px;
        }
        &:after {
          content: '';
          background: url(/img/common/icon_arrow02.svg)no-repeat;
          background-size: 100% auto;
          display: block;
          height: 18px;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transition: all 0.25s;
          transform: translateX(-50%) rotate(90deg);
          width: 12px;
          @include pc {
            top: 50%;
            left: auto;
            right: 35px;
            transform: translateY(-50%) rotate(90deg);
          }
        }
        &:hover {
          cursor: pointer;
          &:after {
            @include pc {
              top: 56%;
            }
          }
        }
        &.-open {
          &:after {
            transform: translateX(-50%) rotate(-90deg);
            @include pc {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
          &:hover {
            &:after {
              @include pc {
                top: 44%;
              }
            }
          }
        }
        &:not(.-open) {
          & + .p-top__newItem__newsBody {
            display: none;
          }
        }
      }
      &Date {
        @include font-Montserrat_regular;
        font-size: 11px;
        line-height: (18/11);
        letter-spacing: 0.16em;
        margin-bottom: 16px;
        position: relative;
        @include pc {
          border-right: 1px solid #000;
          font-size: 14px;
          line-height: (23/14);
          margin-bottom: 0;
          padding-top: 2px;
          padding-bottom: 2px;
          width: 110px;
        }
        &:after {
          content: '';
          background: #776b69;
          display: block;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: -8px;
          width: 13px;
          @include pc {
            content: none;
          }
        }
      }
      &Title {
        @include font-noto_sans;
        font-size: 13px;
        line-height: (19/13);
        letter-spacing: 0.08em;
				word-break: break-all;
        @include pc {
          font-size: 14px;
          line-height: (23/14);
          margin-left: 2.4%;
          padding-right: 3.6%;
          width: calc(100% - 140px);
        }
      }
      &Body {
        @include font-noto_sans;
        font-size: 13px;
        line-height: (19/13);
        letter-spacing: 0.16em;
        padding: 0 6.4% 20px;
        @include pc {
          font-size: 16px;
          line-height: (28/16);
          letter-spacing: 0.14em;
          padding: 13px 2.5% 30px;
        }
        &Inner {
          background: #fff;
          border: 1px solid #ebd1cc;
          padding: 25px 6.4%;
          @include pc {
            padding: 50px 5.2%;
          }
        }
        sup {
          font-size: 13px;
          top: 0;
          @include pc {
            font-size: 16px;
          }
        }
      }
      &Text {
        font-size: 13px;
        text-align: justify;
        @include pc {
          font-size: 16px;
        }
      }
    }
  }
	&__newItem {
		margin-bottom: 30px;
		@include pc {
			margin-bottom: 60px;
		}
		&__newsList.-gray {
			border: 1px solid #bfbfbf;
			> .p-top__newItem__newsItem {
				background: #fff;
				&:not(:first-child) {
					border-top: 1px solid #bfbfbf;
				}
				> .p-top__newItem__newsHead::after {
					display: none;
				}
			}
		}
	}
}
