@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-label {
  @include font-noto_sans_mideum;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  margin-bottom: 9px;
  padding: 3px 11px 2px;
  @include pc {
    font-size: 12px;
    padding: 2px 8px 1px;
  }
  @include pcIE {
    padding: 5px 8px 0;
  }
  &.-bulk {
    background: #0054a5;
  }
  &.-regular {
    background: #e60012;
  }
  &.-large {
    background: #e60012;
    padding: 7px 17px;
    font-size: 11px;
    @include pc {
      font-size: 13px;
      padding: 8px 10px;
      letter-spacing: 0.16em;
    }
    @include pcIE {
      padding: 8px 10px 3px;
    }
  }
}
