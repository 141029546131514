@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-localnav {
  background: #fff;
  margin: 20px 0;
  width: 100%;
  @include pc {}
  &__list {
    flex-wrap: wrap;
    display: flex;
    padding: 10px 20px 20px;
    @include pc {
      padding: 10px 10px 20px 40px;
    }
  }
  &__item {
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
    margin-right: 20px;
    @include pc {
      width: inherit;
      font-size: 14px;
      margin-right: 30px;
    }
    a {
      color: #000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}