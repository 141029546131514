@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-icon {
  &__list {
    display: flex;
    justify-content: center;
    @include pc {
      justify-content: flex-start;
    }

    .c-icon__item {
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &__item {
    display: flex;
    .-row & {
      align-items: center;
      justify-content: center;
      img {
        width: 26px;
        height: 26px;
        @include pc {
          width: 24px;
          height: 24px;
        }
      }
    }
    .-column & {
      flex-direction: column;
      align-items: center;
      width: 20%;
      max-width: 56px;
      @include pc {
        width: 20%;
        max-width: inherit;
      }
      img {
        width: 86%;
        height: auto;
        max-width: 40px;
        max-height: 40px;
        .c-productItem & {
          @media all and (-ms-high-contrast: none) {
            height: 86%;
          }
        }
      }
    }
    .text {
      font-size: 10px;
      transform: scale(0.8);
      white-space: nowrap;
      line-height: 1.2;
      text-align: center;
      @include pc{
        transform: scale(1);
      }
      .-row & {
        @include pc {
          margin-left: 1px;
        }
      }
      .-column & {
        text-align: center;
        margin-top: 4px;
        @include pc {
          margin-top: 4px;
        }
      }
    }
    &.-dk {
      color: #00a5e3;
    }
    &.-moisture {
      color: #007ac3;
    }
    &.-dirt {
      color: #8fc43d;
    }
    &.-silicone {
      color: #f6ab00;
    }
    &.-uv {
      color: #ea535e;
    }
  }
}