@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-link {
  border-bottom: 1px solid #000;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.16em;
  text-decoration: none;
  padding-bottom: 2px;
  &[target="_blank"] {
    padding-right: 23px;
    position: relative;
    &:after {
      content: '';
      background: url(/img/common/icon_blank.svg)no-repeat;
      background-size: 100% auto;
      display: block;
      height: 16px;
      position: absolute;
      top: 52%;
      right: 0;
      transform: translateY(-50%);
      width: 15px;
    }
  }


}