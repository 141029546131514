@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-radio {
  input[type=radio] {
    margin-right: 8px;
  }
  label {
    margin-right: 14px;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    padding: 9px 0;
    &:hover {
      cursor: pointer;
    }
  }
}

@include sp {
  .c-radio.p-default.p-round.-inline > label {
    > input[type=radio] {
      display: inline-block !important;
      height: 10px;
      width: 1px;
      margin-right: 19px;
      display: none;
      &:checked::before {
        content: '';
        display: block !important;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 10px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 3;
        width: 10px;
        height: 10px;
        content: '';
        background: #e60012;
      }
    }
    position: relative;
    padding-left: 5px;
    &::before {
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 10px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 20px;
      height: 20px;
      border: 1px solid #ddd;
      background-color: #fff;
    }
  }
}


// radioカスタマイズ Windowsで表示できない
//.c-radio {
//  input[type=radio] {
//    margin-left: -46px;
//    position: absolute;
//    height: 20px;
//  }
//  label {
//    padding-left: 28px;
//    margin-right: 14px;
//    line-height: 20px;
//    position: relative;
//    overflow: hidden;
//    display: inline-block;
//    vertical-align: middle;
//    min-height: 20px;
//    padding: 9px 0;
//    &:hover {
//      cursor: pointer;
//    }
//  }
//  input[type=radio] {
//    &::before {
//      content: "";
//      display: block;
//      position: absolute;
//      top: 50%;
//      left: 28px;
//      width: 20px;
//      height: 20px;
//      border: 1px solid #dddddd;
//      border-radius: 50%;
//      background: #fff;
//      transform: translate(-50%, -50%);
//    }
//  }
//  input[type=radio]:checked {
//    &::after {
//      content: "";
//      display: block;
//      position: absolute;
//      top: 50%;
//      left: 28px;
//      width: 10px;
//      height: 10px;
//      background: #e60012;
//      border-radius: 50%;
//      transform: translate(-50%, -50%);
//    }
//  }
//}