@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

//HTML
//<div class="c-btnWrap -center">
//<div class="c-btn -white -w460"><a href="" class="">ボタン</a></div>
//<div class="c-btn -black -wFull"><button>ボタン</button></div>
//<div class="c-btn -red -wFull"><input type="submit" value="ボタン"></div>
//</div>
//形
.c-btn {
  a,
  button,
  input {
    width: 100%;
    height: 35px;
    font-size: 13px;
    letter-spacing: 0.16em;
    border-radius: 35px;
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    @include font-noto_sans;
  }
}

//色
.c-btn {
  &.-white {
    a,
    button,
    input {
      background: #fff;
      border: 1px solid #000;
      color: #000;
      &:hover {
        @include pc {
          background: #000;
          color: #fff;
        }
      }
    }
  }
  &.-black {
    a,
    button,
    input {
      background: #000;
      border: 1px solid #000;
      color: #fff;
      &:hover {
        @include pc {
          background: #fff;
          color: #000;
        }
      }
    }
  }
  &.-gray {
    a,
    button,
    input {
      background: #333;
      border: 1px solid #333;
      color: #fff;
      &:hover {
        @include pc {
          background: #fff;
          color: #333;
        }
      }
    }
  }
  &.-red {
    a,
    button,
    input {
      background: #fff;
      color: #e60012;
      border: 1px solid #e2bfd4;
      &:hover {
        @include pc {
          background: #e60012;
          color: #fff;
        }
      }
    }
  }
  &.-redWhite {
    a,
    button,
    input {
      background: #e60012;
      color: #fff;
      border: 1px solid #e60012;
      &:hover {
        @include pc {
          background: #fff;
          color: #e60012;
        }
      }
    }
  }
  &.-green {
    a,
    button,
    input {
      color: #fff;
      background: #00b900;
      border: 1px solid #00b900;
      &:hover {
        @include pc {
          background: #fff;
          color: #000;
        }
      }
    }
  }
}


//横幅
.c-btn {
  &.-w94 {
    width: 94px;
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w130 {
    max-width: 118px;
    width: 100%;
    @include pc {
      max-width: none;
      width: 130px;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w153 {
    max-width: 120px;
    width: 100%;
    @include pc {
      max-width: none;
      width: 153px;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w235 {
    width: 235px;
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w268 {
    width: 268px;
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w445 {
    max-width: 372px;
    width: 100%;
    @include pc {
      max-width: none;
      width: 445px;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w460 {
    max-width: 332px;
    width: 100%;
    @include pc {
      max-width: none;
      width: 460px;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w475 {
    max-width: 347px;
    width: 100%;
    @include pc {
      max-width: none;
      width: 475px;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-wFull {
    max-width: 372px;
    width: 100%;
    @include pc {
      max-width: none;
    }
  }
}

//縦幅
.c-btn {
  &.-h28 {
    a,
    button,
    input {
      border-radius: 28px;
      height: 28px;
      @include pc {
        border-radius: 35px;
        height: 35px;
      }
    }
  }
  &.-h31 {
    a,
    button,
    input {
      border-radius: 31px;
      height: 31px;
      @include pc {
        border-radius: 33px;
        height: 33px;
      }
    }
  }
  &.-h30 {
    a,
    button,
    input {
      border-radius: 31px;
      height: 31px;
      @include pc {
        border-radius: 30px;
        height: 30px;
      }
    }
  }
  &.-h45 {
    a,
    button,
    input {
      border-radius: 45px;
      padding: 11.5px 20px;
      height: auto !important;
      @include pc {
        letter-spacing: 0.18em;
      }
    }
  }
  &.-h48 {
    a,
    button,
    input {
      border-radius: 44px;
      height: 44px;
      @include pc {
        border-radius: 48px;
        height: 48px;
      }
    }
  }
  &.-h67 {
    a,
    button,
    input {
      border-radius: 64px;
      height: 64px;
      @include pc {
        border-radius: 67px;
        height: 67px;
      }
    }
  }
}

//囲み 右寄せ 中央寄せ 左寄せ
.c-btnWrap {
  display: flex;
  margin: 30px auto;
  @include pc {
    margin: 40px auto;
  }
  &.-center {
    justify-content: center;
  }
  &.-right {
    justify-content: flex-end;
  }
  &.-marginBottom {
    margin-bottom: 30px;
    @include pc {
      margin-bottom: 40px;
    }
  }
}

//2列
.c-btnWrap {
  &.-columns {
    justify-content: space-between !important;
    @include sp {
      flex-direction: column;
    }
    .c-btn {
      width: calc(50% - 3.29%);
      @include sp {
        width: 100%;
      }
      a {
        height: 35px;
        @include pc {
          height: 45px;
        }
      }
    }
  }
}


//アイコン
.c-btn {
  &.-blank {
    a {
      padding: 11.5px 40px 11.5px 20px;
      position: relative;
      &:after {
        content: '';
        background: url(/img/common/icon_blank.svg)no-repeat;
        background-size: 100% auto;
        display: block;
        height: 16px;
        position: absolute;
        top: 52%;
        right: 19px;
        transform: translateY(-50%);
        width: 15px;
      }
      &:hover {
        &:after {
          background: url(/img/common/icon_blank_white.svg)no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }
  &.-pdf {
    a {
      padding: 11.5px 40px 11.5px 20px;
      position: relative;
      &:after {
        content: '';
        background: url(/img/common/icon_pdf.svg)no-repeat;
        background-size: 100% auto;
        display: block;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 19px;
        transform: translateY(-50%);
        width: 11px;
      }
      &:hover {
        &:after {
          background: url(/img/common/icon_pdf_white.svg)no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }
}

//個別
.c-btn {
  &.-amazon {
    a,
    button,
    input {
      background: #fff;
      border: 2px solid #ff9900;
      position: relative;
      &:hover {
        img {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
      img {
        width: 94px;
        height: auto;
        position: absolute;
        margin-top: 4px;
        top: 50%;
        left: 50%;
        transition: 0.2s ease-in-out;
        transform: translate(-50%, -50%);
      }
    }
    &.-h45 {
      a,
      button,
      input {
        height: 45px !important;
        padding: 0;
      }
    }
  }
}
