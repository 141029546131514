@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-sec {
  &.-flesh {
    background: #fee5e0;
  }
  &__inner {
    margin: 0 5%;
    @include pc {
      margin: 0 auto;
      max-width: 1440px;
      padding: 0 3.15%;
    }
    &.-small {
      @include pc {
        max-width: 950px;
        padding: 0;
      }
    }
  }
  &__title {
    padding: 40px 0 32px;
    text-align: center;
    flex: 0 0 auto;
    @include pc {
      padding: 63px 0 32px;
    }
    &.-line {
      @include font-noto_sans;
      border-left: 5px solid #e60012;
      font-size: 18px;
      letter-spacing: 0.16em;
      line-height: 1;
      padding: 2px 0 2px 10px;
      text-align: left;
      @include pc {
        font-size: 22px;
        padding: 0 0 0 10px;
      }
			@at-root .l-container.-shopping h1.c-sec__title.-line {
				margin-top: 20px;
				@include pc {
					margin-top: 125px;
				}
			}
    }
    &JP {
      @include font-noto_sans;
      display: block;
      @include fz(13);
      letter-spacing: 0.14em;
      @include pc {
        @include vw(18);
      }
    }
  }
}
