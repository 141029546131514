@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-login {
  .c-btnWrap {
    margin: 0;
  }
  &__inner {
    @include pc {
      display: flex;
    }
  }
  &__main {
    background: #e7eeef;
    padding-bottom: 30px;
    text-align: center;
    @include pc {
      padding-bottom: 62px;
      width: 66.5%;
    }
  }
  &__entry {
    background: #f7f7f7;
    text-align: center;
    @include pc {
      width: 33.5%;
    }
    &Inner {
      @include font-noto_sans;
      padding: 30px 10%;
      @include pc {
        padding: 55px 8% 0;
      }
    }
    .l-login__title {
      display: none;
      @include pc {
        display: inline-block;
      }
    }
    &Text {
      font-size: 11px;
      letter-spacing: 0.16em;
      line-height: (17/11);
      margin-top: 10px;
      text-align: center;
      @include pc {
        @include vw(11);
        margin-top: 12px;
      }
    }
  }
  &__columns {
    @include font-noto_sans;
    @include pc {
      display: flex;
    }
  }
  &__column {
    @include pc {
      padding: 0 4%;
      width: 50%;
    }
    &.-line {
      border-top: 1px solid #a4a5a5;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10%;
      position: relative;
      padding-top: 21px;
      @include pc {
        border-top: none;
        border-left: 1px solid #a4a5a5;
        margin: 0;
        padding-top: 0;
      }
      &:before {
        content: 'or';
        @include font-Montserrat_medium;
        background: #e7eeef;
        color: #000;
        font-size: 12px;
        letter-spacing: 0.04em;
        padding: 0 9px;
        position: absolute;
        top: -11px;
        left: 50%;
        transform: translateX(-50%);
        @include pc {
          padding: 6px 0 7px;
          top: 50%;
          left: -7px;
          transform: translate(0, -50%);
        }
      }
      .c-btnWrap {
        @include pc {
          justify-content: center;
          width: 100%;
        }
      }
    }
    .l-login__columns {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 0 10%;
      @include pc {
        display: inherit;
        margin-bottom: 0;
        padding: 0;
      }
    }
    .l-login__column {
      &.-btn {
        margin-right: 3%;
        width: 47%;
        @include pc {
          margin-right: 0;
          margin-bottom: 9px;
          width: auto;
        }
      }
      &.-link {
        width: 50%;
        @include pc {
          width: auto;
        }
      }
    }
  }
  &__title {
    @include font-noto_sans;
    border-bottom: 2px solid #000;
    @include fz(15);
    display: inline-block;
    letter-spacing: 0.26em;
    margin-top: 26px;
    margin-bottom: 22px;
    padding-bottom: 2px;
    @include pc {
      @include font-noto_sans_mideum;
      border-bottom: 3px solid #000;
      @include vw(18);
      margin-top: 35px;
      margin-bottom: 30px;
      padding-bottom: 1px;
    }
  }
  &__input {
    margin-bottom: 12px;
    padding: 0 6%;
    @include pc {
      padding: 0 5.2%;
    }
  }
  .c-form__inputText {
    @include font-noto_sans;
    background: #fff;
    font-size: 14px;
    letter-spacing: 0.16em;
    margin-bottom: 1px;
    padding: 12px 5% 15px;
    width: 100%;
    @include pc {
      padding: 7px 5% 6px;
    }
  }
  #login_email.c-form__inputText {
    letter-spacing: .12em;
  }
  input::placeholder {
    color: #a3a3a3;
  }
  &__checkbox {
    font-size: 11px;
    letter-spacing: 0.16em;
    line-height: (23/12);
    margin-bottom: 8px;
    @include pc {
      @include vw(12);
      margin-bottom: 20px;
    }
  }
  input[type=checkbox] {
    display: none;
    margin: 0;
  }
  input[type=checkbox]:checked+.c-form__labelCheck:after {
    content: "";
    background: url(/img/common/icon_cheack_on.svg)no-repeat;
    background-size: 100% auto;
    box-sizing: border-box;
    display: block;
    height: 13px;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    left: 3px;
    width: 16px;
  }
  input[type=checkbox]:checked+.c-form__labelCheck:before {
    display: none;
  }
  .c-form__labelCheck {
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    margin-right: -13px;
    position: relative;
    padding: 0 0 0 28px;
    &:before {
      content: "";
      background: url(/img/common/icon_cheack.svg)no-repeat;
      background-size: 100% auto;
      box-sizing: border-box;
      display: block;
      height: 13px;
      margin-top: -6px;
      position: absolute;
      top: 50%;
      left: 1px;
      transition: all 0.2s;
      width: 16px;
      @include pc {
        left: 3px;
      }
    }
  }
  &__link {
    a {
      color: #e60012;
      font-size: 10px;
      letter-spacing: 0.1em;
      line-height: (23/12);
      @include pc {
        font-size: 12px;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}
