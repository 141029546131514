@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

//5〜200pxまで5px刻みで生成
// SP
//.-sp-padding_top5 {
//  padding-top: 5px
//}
//
//.-sp-padding_right_5 {
//  padding-right: 5px
//}
//
//.-sp-padding_bottom_5 {
//  padding-bottom: 5px
//}
//
//.-sp-padding_left_5 {
//  padding-left: 5px
//}
//
// PC
//.-pc-padding_top5 {
//  padding-top: 5px
//}
//
//.-pc-padding_right_5 {
//  padding-right: 5px
//}
//
//.-pc-padding_bottom_5 {
//  padding-bottom: 5px
//}
//
//.-pc-padding_left_5 {
//  padding-left: 5px
//}
//

// 指定するpaddingの範囲を定義
$padding-min: 1;
$padding-max: 200;

@for $i from $padding-min through $padding-max {
  .-sp-padding_top_#{$i * 5} {
    @include sp {
      padding-top: #{$i * 5}px;
    }
    @include tb {
      padding-top: #{$i * 5}px;
    }
  }

  .-sp-padding_right_#{$i * 5} {
    @include sp {
      padding-right: #{$i * 5}px;
    }
    @include tb {
      padding-right: #{$i * 5}px;
    }
  }

  .-sp-padding_bottom_#{$i * 5} {
    @include sp {
      padding-bottom: #{$i * 5}px;
    }
    @include tb {
      padding-bottom: #{$i * 5}px;
    }
  }

  .-sp-padding_left_#{$i * 5} {
    @include sp {
      padding-left: #{$i * 5}px;
    }
    @include tb {
      padding-left: #{$i * 5}px;
    }
  }

  .-pc-padding_top_#{$i * 5} {
    @include pc {
      padding-top: #{$i * 5}px;
    }
  }
  .-pc-padding_right_#{$i * 5} {
    @include pc {
      padding-right: #{$i * 5}px;
    }
  }
  .-pc-padding_bottom_#{$i * 5} {
    @include pc {
      padding-bottom: #{$i * 5}px;
    }
  }
  .-pc-padding_left_#{$i * 5} {
    @include pc {
      padding-left: #{$i * 5}px;
    }
  }
}