@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

/**フォーム**/

.p-term {
  height: 500px;
  border: 1px solid #bfbfbf;
  overflow-y: auto;
  @include font-noto_sans_regular;
  @include pc {
    margin: 0 auto;
    max-width: 950px;
  }
  &__inner {
    font-size: 11px;
    line-height: (26/13);
    padding: 30px;
    @include pc {
      font-size: 13px;
      padding: 40px;
    }
  }
  &__btnWrap {
    margin: 30px 20px 0;
    flex-direction: row-reverse;
    @include sp {
      flex-direction: column;
    }
    @include pc {
      margin: 40px auto 0;
      max-width: 950px;
    }
    .c-btn {
      &.-w460 {
        a,
        button,
        input {
          height: 45px;
        }
        @include sp {
          margin: 0 auto;
          max-width: 269px;
          width: 100%;
        }
        @include tb {
          width: 60%;
        }
      }
      &.-w235 {
        @include sp {
          margin: 0 auto;
          max-width: 269px;
          width: 100%;
        }
        @include tb {
          width: 30%;
        }
        a,
        button,
        input {
          @include tb {
            height: 45px;
          }
          @include pc {
            height: 45px;
          }
        }

      }
    }
    .c-btn + .c-btn {

      @include sp {
        margin-top: 20px;
      }
      @include tb {
        margin-right: 3%;
      }
      @include pc {
        margin-right: 30px;
      }
    }
  }
}
