@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-mypage {
  background: #e7eeef;
  margin-bottom: 30px;
  padding-bottom: 10px;
  overflow: hidden;
  &.-gray {
    background: #f7f7f7;
    @include pc {
      background: none;
    }
  }
  @include pc {
    background: none;
    margin-bottom: 61px;
    padding-bottom: 0;
  }
  &__header {
    @include sp {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      margin-bottom: 20px;
    }
    @include tb {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      margin-bottom: 20px;
    }
  }
  &__foot {
    width: 100%;
    padding-bottom: 20px;
    @include pc {
      max-width: 618px;
      margin: 0 auto;
    }
  }
  &__headerLeft {
    margin: 0;
    width: 100%;
    @include pc {
      max-width: 618px;
      margin: 0 auto;
    }
  }
  &__headerRight {
    margin: 0;
    position: absolute;
    top: 75px;
    right: 3.2%;
    width: 100px;
    @include sp {
      display: none;
    }
  }
  .c-sec {
    &__inner {
      padding-bottom: 20px;
      @include pc {
        display: flex;
        justify-content: space-between;
        padding: 0 3.192% 40px;
      }
    }
    &__title {
      margin-bottom: 0;
      padding-top: 27px;
      padding-bottom: 20px;
      @include pc {
        padding-top: 38px;
        padding-bottom: 32px;
      }
      img {
        margin: 0 auto;
        max-width: 162px;
        width: 48%;
        @include pc {
          max-width: 254px;
          width: 28.948%;
        }
      }
      &JP {
        margin-top: 7px;
        @include pc {
          margin-top: 11px;
        }
      }
    }
  }
  &__main {
    position: relative;
    @include pc {
      background: #e7eeef;
      padding: 0 2.31% 0;
      width: 71.97%;
    }
    .-gray & {
      @include pc {
        background: #f7f7f7;
        padding-bottom: 40px;
      }
    }
    .c-btnWrap {
      .-gray {
        a {
          font-size: 12px;
          letter-spacing: 0.14em;
        }
      }
    }
  }
  .c-product {
    border: 1px solid #bad3d6;
  }
  &__sub {
    display: none;
    @include pc {
      display: block;
      width: 25.682%;
    }
  }
  &__head {
    margin-bottom: 20px;
    @include pc {
      margin-bottom: 18px;
    }
    .c-product {
      padding: 9px 6% 2px;
      @include pc {
        display: flex;
        padding: 22px 0 20px;
      }
      &Main {
        @include pc {
          padding-right: 0.845%;
          padding-bottom: 0;
          width: 50%;
        }
        .swiper-pagination {
          bottom: -3px;
          @include pc {
            bottom: 2px;
            padding-right: 6%;
          }
          &-bullet {
            margin: 0 3px !important;
            @include pc {
              height: 7px;
              width: 7px;
            }
          }
        }
        &__thumb {
          min-height: 172px;
          @media all and (-ms-high-contrast: none) {
            height: 172px;
          }
          @include pc {
            min-height: 396px;
            @media all and (-ms-high-contrast: none) {
              height: 396px;
            }
          }
        }
        .c-logo {
          margin-top: -12px;
          @include pc {
            margin-top: -24px;
            padding-left: 6.5%;
          }
        }
        &__title {
          @include pc {
            @include vw(18);
            margin-top: 13px;
          }
        }
      }
      &Item {
        padding: 9px 0 18px;
        @include pc {
          border-top: none;
          border-left: 1px solid #b7bdbf;
          padding: 0 3% 0 3.4%;
          width: 50%;
        }
        &:before {
          @include pc {
            content: none;
          }
        }
        &__date {
          @include font-Montserrat;
          border-bottom: 1px solid #a0a0a0;
          font-size: 12px;
          letter-spacing: 0.16em;
          line-height: (20/12);
          padding-top: 1px;
          padding-bottom: 8px;
          text-align: right;
          @include pc {
            font-size: 15px;
            line-height: (23/15);
            padding-bottom: 6px;
          }
        }
        &__fav {
          right: 3px;
          @include pc {
            position: static;
            margin-top: 14px;
            margin-left: auto;
          }
        }
        &__title {
          @include fz(15);
          margin-top: 4px;
          margin-bottom: 16px;
          padding-right: 0;
          @include pc {
            @include vw(18);
            margin-bottom: 12px;
          }
          a {
            padding-left: 23px;
            &:before {
              @include pc {
                height: 20px;
                width: 13px;
              }
            }
          }
        }
        &__columns {
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 13px;
          @include pc {
            justify-content: flex-start;
          }
        }
        &__column {
          width: auto;
        }
        &__sum {
          margin-left: 5px;
        }
        &__price {
          margin-bottom: 0;
        }
        &__feature {
          margin-left: 4px;
          margin-bottom: 9px;
          @include pc {
            margin-bottom: 15px;
          }
        }
        .c-tag {
          display: inline;
        }
        .c-icon__list {
          @include pc {
            margin: 0 0 51px 4px;
          }
        }
        .c-icon__item {
          margin-left: 0;
          width: 20%; //          img {
          //            width: 93%;
          //            @include pc {
          //              width: 85%;
          //            }
          //          }
        }
        .c-btnWrap {
          padding-top: 20px;
          position: relative;
          @include pc {
            padding-top: 23px;
          }
          &:before {
            @include pc {
              content: '';
              background: url(/img/common/line_dot_gray.svg)repeat-x;
              display: block;
              height: 3px;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__columns {
    @include pc {
      display: flex;
      justify-content: space-between;
    }
  }
  &__column {
    margin-bottom: 20px;
    @include pc {
      width: 49.044%;
    }
    .c-product {
      padding: 18px 6% 20px;
      @include pc {
        padding: 28px 4.4% 28px 6.612%;
      }
      &Item {
        &__title {
          margin-bottom: 19px;
          a {
            &:before {
              height: 14px;
              width: 9px;
            }
          }
        }
        &__fav {
          top: 63px;
          right: 5%;
        }
      }
      .c-btn {
        margin-left: auto;
      }
    }
  }
  &__text {
    @include font-noto_sans_regular;
    @include fz(15);
    letter-spacing: 0.16em;
    line-height: (20/15);
    @include pc {
      @include vw(18);
      letter-spacing: 0.14em;
      line-height: (24/18);
    }
    &.-center {
      text-align: center;
    }
  }
  &__info {
    @include pc {
      background: #e7eeef;
      padding: 28px 8.7%;
    }
    &Head {
      margin-bottom: 18px;
      padding-top: 16px;
      text-align: center;
      @include pc {
        margin-bottom: 20px;
        padding-top: 0;
      }
    }
    &Title {
      @include font-noto_sans;
      font-size: 11px;
      letter-spacing: 0.2em;
      margin-bottom: 12px;
      @include pc {
        font-size: 13px;
        margin-bottom: 4px;
      }
    }
    &Name {
      @include font-noto_sans;
      border-bottom: 2px solid #fff;
      display: inline-block;
      @include fz(17);
      letter-spacing: 0.24em;
      line-height: (23/18);
      padding: 0 3px 6px;
      @include pc {
        border-bottom: 3px solid #000;
        @include vw(18);
        padding: 0 8px 3px;
      }
    }
    &Columns {
      display: flex;
      position: relative;
      &:after {
        content: '';
        background: #a0a0a0;
        display: block;
        height: 56%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1px;
      }
    }
    &Column {
      color: #000;
      display: flex;
      align-items: center;
      padding: 17px 3.5% 16px;
      width: 50%;
      @include pc {
        color: #333;
        padding: 18px 3.5% 16px;
      }
      .p-mypage__info {
        &Title {
          @include font-noto_sans;
          @include fz(12);
          letter-spacing: 0.16em;
          line-height: (18/12);
          margin-bottom: 0;
          width: 35%;
          @include pc {
            @include vw(12);
            width: 50%;
          }
        }
        &Value {
          @include font-Montserrat_semiBold;
          color: #e60012;
          @include fz(18);
          letter-spacing: 0.06em;
          line-height: (25/18);
          text-align: right;
          width: 65%;
          @include pc {
            @include vw(18);
            width: 50%;
          }
          &.-small {
            font-size: 13px;
            @media screen and (max-width: 320px) {
              font-size: 1rem;
            }
          }
        }
      }
      &.-block {
        display: block;
        > .p-mypage__infoTitle {
          width: 100%;
          margin-bottom: 5px;
        }
        > .p-mypage__infoValue {
          width: 100%;
        }
      }
    }
    &List {
      background: #fff;
      margin-bottom: 10px;
      @include pc {
        border: 1px solid #bad3d6;
      }
      &.-regular {
				background: none;
        border: none;
        margin-top: 14px;
        margin-bottom: 0;
        @include pc {
          margin-top: 18px;
        }
        .p-mypage__info {
          &Item {
            display: flex;
						background: #fff;
            @include pc {
							background: none;
              border-top: none;
            }
            &.-link {
              background: #333;
              margin-bottom: 24px;
            }
          }
          &Column {
            padding-top: 12px;
            padding-bottom: 11px;
            width: 100%;
            @include pc {
              background: #fff;
              border: 1px solid #bad3d6;
              padding-top: 10px;
              padding-bottom: 7px;
							margin-top: 20px;
            }
          }
          &Link {
            @include pc {
              background: #fff;
              border-right: 1px solid #bad3d6;
              border-left: 1px solid #bad3d6;
              border-bottom: 1px solid #bad3d6;
							padding-right: 3.5%;
							padding-left: 3.5%;
            }
          }
          &Title {
            width: 40%;
          }
        }
      }
      .p-mypage__infoItem {
        &:first-child {
          border-top: none;
        }
      }
    }
    &Item {
      border-top: 2px solid #333;
      @include pc {
        border-top: 1px solid #bad3d6;
      }
    }
    &Note {
      @include font-noto_sans;
      font-size: 11px;
      letter-spacing: 0.16em;
      line-height: 1.7;
      @include pc {
        color: #888;
      }
    }
    &Date {
      @include font-noto_sans;
      @include fz(14);
      letter-spacing: 0.22em;
      text-align: right;
      width: 60%;
      @include pc {
        @include vw(14);
      }
    }
    &Link {
      @include font-noto_sans;
      padding: 3px 0;
      text-align: right;
      width: 100%;
      @include pc {
        padding: 16px 0;
        text-align: center;
      }
      a {
        color: #fff;
        font-size: 12px;
        letter-spacing: 0.12em;
        @include pc {
          color: #e60012;
          text-decoration: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__menu {
    background: #f7f7f7;
    padding: 40px 12%;
    &Title {
      @include font-noto_sans_regular;
      @include fz(18);
      letter-spacing: 0.12em;
      line-height: (23/18);
      margin-bottom: 10px;
      @include pc {
        @include vw(18);
      }
    }
    &Item {
      a,
      button {
        @include font-noto_sans;
        color: #333;
        font-size: 12px;
        letter-spacing: 0.16em;
        line-height: (27/12);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}