@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-pagetop {
  position: absolute;
  top: -35px;
  right: 24px;
  height: 70px;
  width: 70px;
	.l-container.-shopping & {
		display: none!important;
	}
  a {
    background: #e60012;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    transition: all 0.25s;
    width: 70px;
    &:after {
      content: '';
      background: url(/img/common/icon_arrow04.svg)no-repeat;
      background-size: 100% auto;
      display: block;
      height: 17px;
      transform: rotate(-90deg);
      width: 11px;
    }
    &:hover {
      @include pc {
        transform: scale(1.3);
      }
    }
  }
  span {
    display: none;
  }
}
