@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-product {
  background: #fff;
  border: 1px solid #ebd1cc;
  padding: 30px 20px 0;
  position: relative;
  &.-wFull {
    .c-product {
      &Label {
        &__item {
          height: 54px;
          img {
            max-height: 46px;
          }
        }
      }
    }
  }
  &Rank {
    position: absolute;
    top: -8px;
    right: 12px;
    width: 55px;
    z-index: 100;
    @include pc {
      right: 20px;
      width: 70px;
    }
    &.-first,
    &.-second,
    &.-third {
      .c-productRank__inner {
        color: #fff;
      }
      &:before,
      &:after {
        content: '';
        border-style: solid;
        height: 0;
        position: absolute;
        top: auto;
        bottom: -7px;
        left: auto;
        width: 0;
      }
      &:before {
        border-width: 7px 35px 0 0;
        left: 0;
      }
      &:after {
        background: none;
        border-width: 0 35px 7px 0;
        right: 0;
      }
    }
    &.-first {
      background: #d1c578;
      &:before {
        border-color: #d1c578 transparent transparent transparent;
      }
      &:after {
        border-color: transparent #d1c578 transparent transparent;
      }
    }
    &.-second {
      background: #b7bdbf;
      &:before {
        border-color: #b7bdbf transparent transparent transparent;
      }
      &:after {
        border-color: transparent #b7bdbf transparent transparent;
      }
    }
    &.-third {
      background: #d19378;
      &:before {
        border-color: #d19378 transparent transparent transparent;
      }
      &:after {
        border-color: transparent #d19378 transparent transparent;
      }
    }
    &:after {
      content: '';
      background: url(/img/top/rank_bg.svg)no-repeat;
      background-size: 100% auto;
      display: block;
      height: 44px;
      position: absolute;
      top: 0;
      left: 0;
      width: 55px;
      @include pc {
        height: 52px;
        width: 70px;
      }
    }
    &__inner {
      @include font-Montserrat_medium;
      color: #333;
      font-size: 10px;
      letter-spacing: 0.12em;
      line-height: 1.2;
      padding: 6px 0 4px;
      position: relative;
      text-transform: uppercase;
      z-index: 10;
      @include pc {
        padding: 8px 0 4px;
      }
    }
    &__num {
      font-size: 12px;
      @include pc {
        font-size: 18px;
      }
    }
  }
  &Main {
    text-align: left;
    padding-bottom: 16px;
    @include pc {
      padding-bottom: 23px;
    }
    .swiper-container {
      display: flex;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
			@include pcIE {
				display: block;
				.p-top__recommend & {
					display: flex;
				}
			}
			@include pcIE {
				.l-pickup & {
					display: flex;
				}
			}
    }
    .swiper-pagination {
      bottom: 0;
      text-align: right;
      &-bullet {
        background: #fff;
        border: 1px solid #000;
        height: 6px;
        margin: 0 2px;
        opacity: 1;
        width: 6px;
        &-active {
          background: #000;
        }
      }
    }
    &__thumb {
      display: flex;
      align-items: center;
      min-height: 140px;
      margin-bottom: 3px;
      width: 100%;
      @include pc {
        min-height: 178px;
      }
      a {
        display: block;
        width: 100%;
        &:hover {
          img {
            @include pc {
              transform: scale(1.05);
            }
          }
        }
      }
      img {
        max-height: 112px;
        max-width: 90%;
				@include pc {
					max-height: 135px;
					transition: all 0.2s;
					width: auto;
					max-width: 80%;
				}
				:not(.p-top__recommend),
				:not(.l-pickup) {
					@include sp320{
						@at-root _:lang(x)::-ms-backdrop, & {
							width: 90%;
							height: auto;
						}
					}
					@include pc {
						@at-root _:lang(x)::-ms-backdrop, & {
							width: 80%;
						}
					}
					@at-root _:lang(x)::-ms-backdrop, & {
						max-height: none;
						max-width: none;
					}
				}
      }
    }
    .c-logo {
      margin-top: -13px;
    }
    &__title {
      @include font-Montserrat;
      @include fz(15);
      letter-spacing: 0.16em;
      line-height: (20/15);
      margin-top: 4px;
      @include pc {
        @include vw(18);
        line-height: (23/18);
        margin-top: 7px;
      }
    }
  }
  &Item {
    padding: 15px 0 18px;
    position: relative;
    text-align: left;
    @include pc {
      padding: 20px 0 18px;
    }
    &:before {
      content: '';
      background: url(/img/common/line_dot.svg)repeat-x;
      display: block;
      height: 3px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .c-btnWrap {
      margin: 0;
    }
    &__title {
      @include font-Montserrat;
      @include fz(13);
      letter-spacing: 0.16em;
      line-height: (20/13);
      margin-bottom: 19px;
      padding-right: 40px;
      position: relative;
      @include pc {
        @include vw(15);
        line-height: (23/15);
        margin-bottom: 16px;
      }
      a {
        color: #000;
        display: flex;
        align-items: center;
        padding-left: 20px;
        text-decoration: none;
        transition: all 0.25s;
        width: 100%;
        &:before {
          content: '';
          background: url(/img/common/icon_arrow02.svg)no-repeat;
          background-size: 100% auto;
          display: block;
          height: 13px;
          margin-right: 10px;
          position: absolute;
          top: 50%;
          left: 0;
          transition: all 0.25s;
          transform: translateY(-50%);
          width: 8px;
          @include pc {
            height: 16px;
            width: 10px;
          }
        }
        &:hover {
          color: #e60012;
          &:before {
            @include pc {
              left: 5px;
            }
          }
        }
      }
    }
    &__fav {
      height: 18px;
      position: absolute;
      top: 15px;
      right: 0;
      width: 18px;
      z-index: 10;
      @include pc {
        top: 20px;
        width: 20px;
      }
      &Btn {
        content: '';
        background: url(/img/common/icon_fav.png)no-repeat;
        background-size: 100% auto;
        display: block;
        height: 18px;
        width: 18px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s;
        @include pc {
          width: 20px;
        }
        &.-active {
          background: url(/img/common/icon_fav_on.png)no-repeat;
          background-size: 100% auto;
        }
      }
      &Wrap {
        position: relative;
        @include pc {
          position: static;
        }
      }
    }
    &__columns {
      display: flex;
      position: relative;
    }
    &__column {
      width: 55%;
      &.-right {
        text-align: right;
				width: 45%;
      }
      &.-single {
        margin-left: auto;
        text-align: right;
      }
      .c-productItem__price {
        display: inline-block;
      }
      .c-btnWrap {
        margin: 0;
      }
    }
    &__total,
    &__totalPrice {
      color: #e60012;
      font-size: 11px;
      letter-spacing: 0.16em;
      @include pc {
        font-size: 13px;
      }
      .c-text {
        letter-spacing: 0.12em;
      }
    }
    &__totalPrice {
      position: absolute;
      width: 49%;
      left: 0;
      top: 0;
      text-align: left;
			@include sp320{
				width: 42%;
			}
      @include pc {
        position: static;
        width: auto;
        margin-top: 15px;
      }
      .-pc {
        display: none;
        @include pc {
          display: inline;
        }
      }
    }
    &__comparison {
      @include font-Montserrat;
      color: #333;
      font-size: 13px;
      letter-spacing: 0.14em;
      line-height: (23/13);
      .-red {
        @include font-Montserrat_bold;
      }
    }
    &__sum {
      @include font-noto_sans;
      font-size: 11px;
      line-height: (16/11);
      letter-spacing: 0.16em;
      margin-right: 8px;
      @include pc {
        font-size: 15px;
        line-height: (23/15);
        margin-right: 20px;
      }
      span {
        @include font-Montserrat_medium;
        border: 1px solid #b7bdbf;
        color: #888;
        font-size: 10px;
        line-height: (20/10);
        margin-left: 5px;
        padding: 4px 12px;
        @include pc {
          font-size: 12px;
          line-height: (25/12);
          padding: 6px 15px;
        }
      }
    }
    &__price {
      @include font-Montserrat_semiBold;
      color: #e60012;
      @include fz(25);
      letter-spacing: 0.04em;
      line-height: 1.1;
      margin-bottom: 6px;
      text-align: right;
      @include pc {
        @include vw(35);
        margin-bottom: 9px;
      }
      &Text {
        @include font-noto_sans;
        @include fz(13);
        text-align: left;
        @include pc {
          @include vw(13);
        }
      }
      &Yen {
        @include font-Montserrat_medium;
        @include fz(15);
        letter-spacing: 0.16em;
        @include pc {
          @include vw(15);
        }
      }
      &Tax {
        @include font-noto_sans;
        @include fz(12);
        letter-spacing: 0.16em;
        white-space: nowrap;
        @include pc {
          @include vw(14);
        }
      }
    }
    .c-btn {
      a {
        font-size: 11px;
        @include pc {
          font-size: 13px;
        }
      }
    }
    &__feature {
      @include font-noto_sans;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 11px;
      letter-spacing: 0.14em;
      margin-bottom: 14px;
      @include pc {
        font-size: 13px;
        justify-content: flex-start;
        margin-bottom: 12px;
      }
    }
  }
  &Label {
    &__list {
      display: none;
      font-size: 0;
      @include pc {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &__item {
      border-bottom: 2px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 0;
      height: 40px;
      width: 18%;
      margin-bottom: 10px;
      vertical-align: top;
      cursor: pointer;
      &:not(:nth-of-type(5n)) {
        margin-right: 2%;
      }
      &.-active {
        border-bottom: 2px solid #e60012;
        transition: all 0.2s;
      }
      img {
        width: auto;
        max-width: 80%;
        height: auto;
        max-height: 32px;
        @include IE {
          max-width: 58px;
        }
      }
    }
  }
}
