@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-footer {
  background: #333;
  margin-top: 40px;
  padding: 25px 0 21px;
  position: relative;
  z-index: 10;
  @include pc {
    margin-top: 90px;
    padding: 74px 0 67px;
  }
	.l-container.-shopping & {
		@include pc {
			padding: 38px 0 88px;
		}
	}
  &__inner {
    display: flex;
    justify-content: flex-end;
    max-width: 1440px;
    margin: 0 5%;
    @include pc {
      margin: 0 auto;
      padding: 0 3.15%;
    }
  }
  &__columns {
    width: 100%;
    @include pc {
      display: flex;
    }
    .l-footer__column {
      &:nth-child(1) {
        border-top: 2px solid #8d1922;
        padding-top: 2px;
        @include pc {
          border-top: 3px solid #8d1922;
          margin-right: 5.3%;
          width: 73.41%;
        }
				.l-container.-shopping & {
					width: 100%;
					padding-top: 0;
					margin-right: 0;
					border-top: none;
					text-align: center;
					.l-footer__nav,
					.l-footer__logo {
						display: none;
					}
				}
      }
      &:nth-child(2) {
        @include pc {
          width: 21.29%;
        }
        img {
          width: 100%;
        }
				.l-container.-shopping & {
					display: none!important;
				}
      }
    }
  }
  &__column {
    .l-footer__columns {
      justify-content: space-between;
			.l-container.-shopping & {
				display: block;
			}
    }
  }
  &__logo {
    margin: 30px auto 0;
    text-align: center;
    width: 18%;
    @include pc {
      margin: -9px 0 0;
      max-width: 144px;
      width: auto;
    }
    img {
      width: 100%;
    }
  }
  &__nav {
    @include pc {
      border-top: 3px solid #8d1922;
      display: flex;
      padding-top: 30px;
      padding-bottom: 42px;
    }
    &.-sub {
      margin-top: 13px;
      @include pc {
        margin-top: 0;
      }
      .l-footer__navList {
        display: flex;
        flex-wrap: wrap;
        @include pc {
          width: 100%;
        }
        .l-footer__navItem {
          &:nth-child(1n) {
            @include pc {
              width: 32.508%;
            }
          }
          &:nth-child(2),
          &:nth-child(5) {
            @include pc {
              width: 38.7%
            }
          }
          &:nth-child(3n) {
            @include pc {
              width: calc(100% - (32.508% + 38.7%));
            }
          }
        }
      }
      .l-footer__navItem {
        width: 50%;
        @include pc {
          width: 33.33%;
        }
      }
    }
    a {
      color: #fff;
      text-decoration: none;
      &.-none {
        @include pc {
          pointer-events: none;
        }
      }
    }
    &Inner {
      border-top: 2px solid #8d1922;
      @include pc {
        border-top: none;
      }
      &:nth-child(1) {
        @include pc {
          width: 32.508%;
        }
      }
      &:nth-child(2) {
        @include pc {
          width: 38.7%
        }
      }
      &:nth-child(3) {
        border-bottom: 2px solid #8d1922;
        @include pc {
          border-bottom: none;
          width: calc(100% - (32.508% + 38.7%));
        }
        .l-footer__navHead {
          &:after {
            transform: translateY(-50%);
          }
        }
      }
    }
    &Head {
      @include font-noto_sans_regular;
      color: #fff;
      @include fz(15);
      letter-spacing: 0.16em;
      line-height: (20/15);
      margin-bottom: 0;
      position: relative;
      @include pc {
        @include vw(18);
        letter-spacing: 0.14em;
        line-height: (24/18);
      }
      &:after {
        content: '';
        background: url(/img/common/icon_arrow04.svg)no-repeat;
        background-size: 100% auto;
        display: block;
        height: 14px;
        position: absolute;
        top: 50%;
        right: 12px;
        transition: all 0.25s;
        transform: translateY(-50%) rotate(90deg);
        width: 9px;
        @include pc {
          content: none;
        }
      }
      &.js-accordion {
        padding-top: 19px;
        padding-bottom: 19px;
        @include pc {
          padding-top: 5px;
          padding-bottom: 16px;
        }
      }
      &.-open {
        &:after {
          transform: translateY(-50%) rotate(-90deg);
        }
      }
      &:not(.-open) + .l-footer__navBody {
        @media print,
        screen and (max-width: $point-pc - 1) {
          display: none;
        }
      }
      a {
        display: block;
        padding-top: 19px;
        padding-bottom: 19px;
        @include pc {
          padding-top: 5px;
          padding-bottom: 16px;
        }
      }
    }
    &Body {
      padding-bottom: 15px;
      @include pc {
        padding-bottom: 0;
      }
    }
    &List {}
    &Item {
      @include font-noto_sans;
      @include fz(11);
      letter-spacing: 0.16em;
      line-height: (24/11);
      position: relative;
      @include pc {
        @include vw(12);
        line-height: (28/12);
        margin-bottom: 1px;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__copy {
    @include font-Montserrat_medium;
    color: #888;
    @include fz(10);
    letter-spacing: 0.12em;
    line-height: (28/10);
    text-align: center;
    @include pc {
      @include vw(11);
      letter-spacing: 0.16em;
      line-height: (28/11);
      text-align: left;
    }
		&:only-child {
			text-align: center;
		}
		.l-container.-shopping & {
			text-align: center;
		}
  }
  &__calendar {
    font-size: 12px;
    .block_body {
      background: #fff;
      padding: 14px 4.8% 5px;
    }
    table {
      background: #fff;
      border-collapse: collapse;
      border: none;
      position: relative;
      width: 100%;
      &:first-child {
        margin-bottom: 10px;
        &:before,
        &:after {
          content: '';
          background: #333;
          display: block;
          height: 5px;
          position: absolute;
          bottom: -10px;
          width: 60%;
        }
        &:before {
          left: -15px;
        }
        &:after {
          right: -15px;
        }
      }
      &:nth-child(2) {
        .month {
          border-top: 12px solid #fff;
        }
      }
    }
    .month {
      @include font-Montserrat;
      background: #e60012;
      color: #fff;
      letter-spacing: 0.14em;
      padding: 5px 0;
    }
    .sat {
      color: #357e7a;
    }
    .sun {
      color: #d17a78;
    }
    .off {
      color: #fff;
      position: relative;
      z-index: 10;
      &:after {
        content: '';
        background: #e60012;
        border-radius: 50%;
        display: block;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        z-index: -1;
      }
    }
    .today {
      position: relative;
      z-index: 10;
      &:before {
        content: '';
        background: #000;
        display: block;
        height: 1px;
        position: absolute;
        bottom: 1px;
        left: 50%;
        transform: translateX(-48%);
        width: 20px;
      }
    }
    thead {
      th {
        @include font-noto_sans;
        background: #fff;
        border: none;
        padding: 6px 3px 5px;
        text-align: center;
      }
    }
    tbody {
      td {
        @include font-Montserrat_medium;
        border-top: 1px solid #e5e5e5;
        border-right: none;
        padding: 6px 3px 5px;
        text-align: center;
      }
    }
  }
}