@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-pickup {
  .c-sec {
    &__inner{
      @include pc {
        padding: 0 4.167%;
      }
      & > .l-pickup__itemTitle {
          padding-top: 27px;
          @include pc {
            padding-top: 33px;
          }
      }
    }
    &__title {
      margin-bottom: 0;
      padding-top: 28px;
      padding-bottom: 12px;
      @include pc {
        margin: 0 auto;
        padding: 37px 4.167% 0;
        max-width: 1440px;
      }
      & + .l-pickup__inner {
        .l-pickup__itemTitle {
          @include sp {
            padding-top: 0;
          }
        }
      }
      img {
        width: 138px;
        @include pc {
          width: 14%;
        }
      }
    }
  }
  &__inner {
    border-bottom: 1px solid #fff;
    margin: 0 auto;
    @include pc {
      padding-bottom: 14px;
    }
  }
  &__list {
    @include pc {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &.-single {
      @include pc {
        padding-top: 33px;
      }
    }
    .l-pickup__item {
      &:nth-child(even) {
        @include pc {
          margin-left: 2.492%;
        }
      }
    }
  }
  &__item {
    margin-bottom: 28px;
    @include pc {
      margin-bottom: 26px;
      width: 48.754%;
    }
    &Title {
      @include font-noto_sans;
      @include fz(13);
      letter-spacing: 0.22em;
      margin-bottom: 10px;
      position: relative;
      text-align: center;
      z-index: 10;
      @include pc {
        @include vw(15);
        margin-bottom: 25px;
      }
      &+.l-pickup__list {
        @include pc {
          padding-top: 16px;
        }
      }
      &__inner {
        display: inline-block;
        padding: 0 2% 10px;
        position: relative;
        min-width: 235px;
        @include pc {
          min-width: 250px;
        }
        &:before {
          content: '';
          background: #000;
          display: block;
          height: 19px;
          position: absolute;
          right: 1px;
          bottom: 0;
          width: 1px;
          @include pc {
            right: 0;
          }
        }
        &:after {
          content: '';
          background: url(/img/common/pickup_line.svg)no-repeat;
          background-size: 100% auto;
          display: block;
          height: 20px;
          position: absolute;
          right: 0;
          bottom: -20px;
          width: 100%;
        }
      }
    }
    .c-product {
      padding: 19px 6% 4px;
      @include pc {
        display: flex;
        padding: 19px 0 15px;
      }
      &Main {
        padding-bottom: 18px;
        @include pc {
          padding-bottom: 0;
          width: 50%;
        }
        .swiper-pagination {
          bottom: -4px;
          @include pc {
            bottom: 8px;
            padding-right: 9%;
          }
          &-bullet {
            margin: 0 3px !important;
          }
        }
        &__thumb {
          align-items: center;
          min-height: 155px;
          @include pc {
            min-height: 286px;
          }
          @media all and (-ms-high-contrast: none) {
            height: 286px;
          }
          img {
            @include pc {
              max-height: 103px;
              max-width: 80%;
							@include IE {
								max-height: none;
              	max-width: none;
								width: 80%;
							}
            }
          }
        }
        .c-logo {
          margin-top: -15px;
          @include pc {
            margin-top: -23px;
            padding-left: 9.3%;
          }
          img {
            max-height: 17px;
          }
        }
        &__title {
          line-height: (20/14);
          margin-top: 0;
          @include pc {
            @include vw(14);
            margin-top: 32px;
          }
        }
      }
      &Item {
        border-top: none;
        padding: 17px 0;
        @include pc {
          border-left: 1px solid #b7bdbf;
          padding: 0 4.4%;
          width: 50%;
        }
        &:before {
          @include pc {
            content: none;
          }
        }
        &__fav {
          top: 17px;
          right: 1%;
          @include pc {
            top: -2px;
            right: 4%;
          }
        }
        &__title {
          line-height: (20/14);
          margin-top: 2px;
          padding-right: 0;
          @include pc {
            @include vw(14);
          }
          a {
            padding-left: 17px;
          }
        }
        &__columns {
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 8px;
          @include pc {
            justify-content: flex-start;
          }
          .c-productItem__column {
            &:first-child {
              order: 2;
              @include pc {
                order: 1;
              }
            }
            &:nth-child(2) {
              order: 1;
              @include pc {
                order: 2;
              }
            }
          }
        }
        &__column {
          width: auto;
        }
        &__price {
          text-align: left;
          margin-right: 5px;
          margin-bottom: 0;
        }
        .c-icon__list {
          @include pc {
            margin-bottom: 39px;
          }
        }
        .c-btnWrap {
          padding-top: 20px;
          position: relative;
          &:before {
            @include pc {
              content: '';
              background: url(/img/common/line_dot_gray.svg)repeat-x;
              display: block;
              height: 3px;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            }
          }
          .c-btn {
            a {
              @include pc {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
