@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

// @include font-noto_sans;
.p-list {
  background: rgba(#fee5e0, 1);
  overflow: hidden;
  padding-bottom: 60px;
  &__nav {
    margin: 0 -5%;
    position: relative;
    padding: 20px 0;
    @include sp {
      padding: 0;
    }
    @include pc {
      height: auto;
      margin: 0 auto;
      border-bottom: 1px solid #fff;
    }
    &:before {
      content: '';
      background: #fff;
      display: block;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      @include pc {
        content: none;
      }
    }
    &List {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin: 0 auto;
      @include sp {
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
      @include pc {
        padding: 0 20px;
      }
      a {
        @include font-noto_sans;
        color: #000;
        font-size: 14px;
        letter-spacing: 0.14em;
        text-decoration: none;
        transition: all 0.2s;
        display: flex;
				justify-content: center;
				align-items: center;
				flex: 0 0 auto;
        padding: 8px 20px;
        @include sp {
          font-size: 11px;
          padding: 24px 0px;
          border-bottom: 1px solid #fff;
					height: 100%;
        }
        @include pc {
					display: block;
				}
        &:hover {
          @include pc {
            color: #e60012;
          }
        }
      }
    }
    &Item {
      @include sp {
        width: 25%;
        text-align: center;
      }
      @include pc {}
      &.-current {
        a {
          color: #e60012;
					position: relative;
          @include pc {
            //            border-bottom: 1px solid #e60012;
          }
          &:after {
            @include sp {
              content: '';
              background: #e60012;
              display: block;
              height: 1px;
              position: absolute;
              left: 0;
              bottom: -1px;
              width: 100%;
            }
            @include pc {
              content: none;
            }
          }
        }
      }
    }
  }
  &__head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-top: 33px;
    margin-bottom: 20px;
  }
  &__pageTitle {
    display: flex;
    justify-content: center;
    letter-spacing: 0.28em;
    margin-bottom: 26px;
    .title {
      font-size: 15px;
      margin-top: 0;
      margin-bottom: 0;
      @include font-noto_sans;
      @include pc {
        font-size: 21px;
      }
    }
    .num {
      @include font-Montserrat_regular;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      height: 25px;
      width: 25px;
      flex-basis: 25px;
      letter-spacing: -0.01em;
      position: relative;
      z-index: 2;
      @include pc {
        font-size: 15px;
        margin-left: 3px;
        height: 31px;
        width: 31px;
        flex-basis: 31px;
      }
      &::before {
        content: "";
        background: #e60012;
        border-radius: 50%;
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        @include pc {
          height: 31px;
          width: 31px;
        }
      }
    }
  }
  &__select {
    display: flex;
    justify-content: center;
    .c-select1 + .c-select1 {
      margin-left: 10px;
    }
    .c-select1 {
      @include sp {
        width: 38%;
      }
    }
  }
  &__inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    @include pc {
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
    }
  }
  &__product {
    margin-bottom: 20px;
    padding-top: 20px;
    @include pc {
      margin-left: 2%;
      padding: 20px 20px 0;
      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }
    a {
      text-decoration: none;
      color: #000;
    }
    &:hover {
      .c-productMain__thumb img {
        @include pc {
          transform: scale(1.05);
        }
      }
      .c-productItem__title {
        color: #e60012;
      }
    }
  }
  .c-product {
    max-width: 500px;
    width: 100%;
    border: none;
    @include pc {
      width: 32%;
      max-width: inherit;
    }
    &Main {
      padding-bottom: 22px;
      position: relative;
      @include pc {
        padding-bottom: 20px;
      }
      &__label {
        display: flex;
        min-height: 28px;
        z-index: 10;
        @include pc {
          position: absolute;
          top: 0;
          right: 0;
        }
        &:empty {
          min-height: inherit;
          @include pc {
            min-height: 28px;
          }
        }
      }
      &__contents {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        @include pc {
          flex-direction: column;
        }
      }
      &__body {
        width: 50%;
        @include pc {
          width: 100%;
        }
      }
      &__title {
        @include pc {
          margin-top: 12px;
        }
      }
      &__thumb {
        width: 50%;
        display: flex;
        align-items: center;
        min-height: 60px;
        justify-content: center;
        min-height: 80px;
        @include pc {
          margin-bottom: 8px;
          width: 100%;
          min-height: 220px;
        }
        &.-h163 {
          @include pc {
            min-height: 163px;
          }
          img {
            @include pc {
              max-height: 150px;
              max-width: 77%;
            }
            @include IE {
              max-width: 200px;
            }
            @include pcIE {
              max-width: 240px;
            }
          }
        }
        &.-h250 {
          @include pc {
            margin-bottom: 44px;
            min-height: 250px;
          }
          img {
            @include pc {
              max-height: 243px;
              max-width: 73%;
            }
            @include IE {
              max-width: 200px;
            }
            @include pcIE {
              max-width: 240px;
            }
          }
        }
        img {
          width: auto;
          max-height: 150px;
          max-width: 90%;
          @include pc {
            transition: 0.25s ease-in-out;
            width: auto;
            max-height: 200px;
            max-width: 80%;
          }
          @include IE {
            max-width: 200px;
          }
          @include pcIE {
            max-width: 240px;
          }
        }
      }
      .c-label {
        padding: 0 8px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .c-logo {
        margin-top: inherit;
      }
    }
    &Item {
      @include sp {
        padding-bottom: 4px;
      }
    }
    &Item__columns {
      display: inherit;
    }
    &Item__column {
      width: inherit;
    }
    &Item__title {
      position: relative;
      width: 100%;
      padding-left: 20px;
      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 10px;
        height: 16px;
        margin-right: 10px;
        content: '';
        background: url(/img/common/icon_arrow02.svg) no-repeat;
        background-size: 100% auto;
        transition: .3s ease-in-out;
        transform: translateY(-50%);
      }
    }
  }
  &__resultText {
    @include font-noto_sans_regular;
    @include fz(15);
    letter-spacing: 0.16em;
    line-height: (20/15);
    width: 100%;
    @include pc {
      @include vw(18);
      letter-spacing: 0.14em;
      line-height: (24/18);
    }
    &.-center {
      text-align: center;
    }
  }
}