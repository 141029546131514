@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-header {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
	position: fixed;
	top: 0;
	width: 100%;
  @include pc {
    height: 5.813rem;
    margin: 0 auto 60px;
    position: relative;
  }
  &.-logoff {
    .l-header__nav {
      &List.-right {
        .l-header__navItem {
          &:nth-child(2) {
            &:after {
              content: none;
              @include pc {
                content: '';
              }
            }
          }
        }
      }
    }
  }
  &.-login {
    .l-header__nav {
      &List.-right {
        .l-header__navItem {
          &:nth-child(2) {
            &:before {
              content: '';
              background: #a0a0a0;
              display: block;
              height: 43px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              @include pc {
                content: none;
              }
            }
            &:after {
              content: '';
              background: #a0a0a0;
              display: block;
              height: 43px;
              position: absolute;
              left: auto;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              @include pc {
                content: none;
              }
            }
          }
          &.-open {
            &:before,
            &:after {
              background: #333 !important;
            }
          }
          &.-cart {
            &:after {
              content: none;
              @include pc {
                content: '';
                background: #a0a0a0;
                display: block;
                height: 43px;
                position: absolute;
                left: -6%;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
              }
            }
          }
        }
      }
    }
  }
  &__site {
    &Logo {
      width: 100%;
      @include pc {
        width: 17.8%;
      }
      a {
        background: #e60012;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 49px;
        @include pc {
          height: 100%;
        }
      }
      img {
        width: 144px;
        @include pc {
          width: 88%;
          max-width: 225px;
        }
      }
    }
    &Search {
      @include font-noto_sans;
      display: flex;
      font-size: 11px;
      letter-spacing: 0.06em;
      line-height: (28/11);
      height: 6em;
      width: 50%;
      @include pc {
        align-items: center;
        @include vw(14);
        line-height: (35/14);
        height: 100%;
        width: 17.6%;
      }
      &.-open {
        .l-header__siteSearch__inner {
          background: #333;
          color: #fff;
          @include pc {
            background: none;
            color: #e60012;
          }
          &:before {
            @include pc {
              content: '';
              background: #e60012;
              display: block;
              height: 2px;
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
              width: 88px;
            }
          }
          &:after {
            border-color: #fff;
            transform: translateX(-50%) rotate(136deg);
            @include pc {
              background: url(/img/common/icon_arrow05.svg)no-repeat;
              background-size: 100% auto;
              transition: all 0.25s;
              transform: translateX(-50%) rotate(0deg);
            }
          }
          span {
            &:before {
              background: url(/img/common/icon_search_white.svg)no-repeat;
              background-size: 100% auto;
              @include pc {
                background: url(/img/common/icon_search_red.svg)no-repeat;
                background-size: 100% auto;
              }
            }
          }
        }
      }
      &__inner {
        display: flex;
        justify-content: center;
        padding-top: 13px;
        position: relative;
        width: 100%;
        @include pc {
          align-items: center;
          cursor: pointer;
          height: 100%;
          padding-top: 4px;
          padding-bottom: 4px;
        }
        &:after {
          content: '';
          display: block;
          height: 6px;
          position: absolute;
          left: 50%;
          bottom: 19px;
          transition: transform 0.25s;
          transform: translateX(-50%) rotate(-45deg);
          width: 6px;
					border-left: 1px solid #000;
					border-bottom: 1px solid #000;
					backface-visibility: hidden;
          @include pc {
						background: url(/img/common/icon_arrow03.svg)no-repeat;
          	background-size: 100% auto;
            bottom: 12px;
						width: 10px;
						border: none;
						transform: translateX(-50%) rotate(0deg);
          }
        }
        span {
          display: flex;
          justify-content: center;
          width: 100%;
          @include pc {
            align-items: center;
          }
          &:before {
            content: '';
            background: url(/img/common/icon_search.svg)no-repeat;
            background-size: 100% auto;
            display: block;
            height: 16px;
            margin-top: 5px;
            margin-right: 14px;
            width: 15px;
            @include pc {
              height: 20px;
              margin-top: 0;
              margin-right: 9%;
              width: 19px;
            }
          }
        }
        &:hover {
          @include pc {
            color: #e60012;
          }
          &:before {
            @include pc {
              content: '';
              background: #e60012;
              display: block;
              height: 2px;
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
              width: 88px;
            }
          }
          &:after {
            @include pc {
              background: url(/img/common/icon_arrow05.svg)no-repeat;
              background-size: 100% auto;
              transition: all 0.25s;
              transform: translateX(-50%) rotate(0deg);
            }
          }
          span {
            &:before {
              @include pc {
                background: url(/img/common/icon_search_red.svg)no-repeat;
                background-size: 100% auto;
              }
            }
          }
        }
      }
      &__menu {
        @include pc {
          background: #ffe5e0;
          padding: 46px 4.2% 58px;
          position: absolute;
          top: 5.813rem;
          left: 0;
          transition: all 0.25s;
          width: 100%;
          z-index: 100;
          & > * {
            transition: opacity 0.3s cubic-bezier(.17, .84, .44, 1) 0.3s, transform 0.3s cubic-bezier(.17, .84, .44, 1) 0.3s;
          }
          &.v {
            &-enter,
            &-leave-to {
              opacity: 0;
              padding-top: 0;
              padding-bottom: 0;
              & > * {
                opacity: 0;
                transform: translateY(40px);
              }
            }
          }
        }
      }
      &__columns {
        @include pc {
          display: flex;
          justify-content: space-between;
        }
      }
      &__column {
        width: 48.8%;
        .l-header {
          &__menuList {
            @include pc {
              display: inherit;
            }
            &:first-child {
              border-top: 1px solid #e60012;
            }
          }
          &__menuItem {
            @include pc {
              border-bottom: 1px solid #e60012;
              width: 100%;
            }
            &:after {
              @include pc {
                content: none;
              }
            }
            a {
              @include pc {
                padding-top: 27px;
                padding-bottom: 22px;
              }
              &:hover {
                &:after {
                  @include pc {
                    right: 2px;
                  }
                }
              }
              &:after {
                @include pc {
                  top: 50%;
                  right: 7px;
                  transform: translateY(-50%);
                }
              }
            }
          }
          &__navHead {
            @include pc {
              @include font-noto_sans_bold;
              color: #000;
              font-size: 15px;
              margin-bottom: 0;
            }
            &.js-accordion {
              @include pc {
                padding: 19px 0 7px;
              }
            }
            a {
              @include pc {
                @include font-noto_sans_bold;
              }
            }
          }
          &__navBody {
            @include pc {
              padding-bottom: 18px;
            }
          }
          &__navList {
            @include pc {
              display: inherit;
            }
          }
          &__navItem {
            @include pc {
              margin: 0;
              width: 100%;
            }
            &:hover {
              a {
                @include pc {
                  border-bottom: none;
                }
                &:after {
                  @include pc {
                    background: url(/img/common/icon_arrow02.svg) no-repeat;
                    background-size: 100% auto;
                  }
                }
              }
            }
            a {
              @include pc {
                border-bottom: none;
                padding: 7px 0;
                text-align: left;
              }
            }
            &.-more{
              a{
                text-align: right;
                padding: 7px 25px;
              }
            }
          }
        }
      }
    }
  }
  &__nav {
    @include font-noto_sans;
    display: flex;
    align-items: center;
    @include fz(10);
    letter-spacing: 0.1em;
    line-height: (15/10);
    width: 50%;
    @include pc {
      @include vw(14);
      letter-spacing: 0.06em;
      line-height: (35/14);
      width: calc(100% - (17.8% + 17.6%));
    }
    &List {
      display: flex;
      @include pc {
        height: 100%;
      }
      &.-left {
        display: none;
        margin-left: 2.5%;
        width: 63.4%;
        @include pc {
          display: flex;
          width: 72%;
        }
        .l-header__navItem {
          &:first-child {
            & > a {
              position: relative;
              &:before {
                @include pc {
                  content: '';
                  background: #a0a0a0;
                  display: block;
                  height: 43px;
                  position: absolute;
                  left: -55%;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                }
              }
            }
          }
          &:nth-child(4) {
            margin-left: 2.8%;
            padding-right: 3.6%;
            @media all and (-ms-high-contrast: none) {
              margin: 0;
              padding-right: 0;
            }
            & > a {
              position: relative;
              &:before {
                @include pc {
                  content: '';
                  background: #a0a0a0;
                  display: block;
                  height: 43px;
                  position: absolute;
                  right: -46%;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                }
              }
            }
          }
          &:nth-child(5) {
            margin: 0 6%;
            @media all and (-ms-high-contrast: none) {
              margin: 0 3%;
            }
            &.-more {
              @include pc {
                display: none;
              }
            }
          }
        }
      }
      &.-right {
        background: #e7eeef;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        @include pc {
          width: 28%;
        }
        .l-header__navItem {
          width: 33.33%;
          position: relative;
          @include pc {
            width: 50%;
          }
          &.-open {
            a,
            button {
              background: #333;
              color: #fff;
              &:after {
								border-color: #fff;
								transform: translateX(-50%) rotate(136deg);
								@include pc {
									background: url(/img/common/icon_arrow06.svg)no-repeat;
									background-size: 100% auto;
									transform: translateX(-50%) rotate(180deg);
								}
              }
            }
          }
          &:after {
            content: '';
            background: #a0a0a0;
            display: block;
            height: 43px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            @include pc {
              left: -6%;
            }
          }
          &:first-child {
            &:after {
              content: none;
            }
          }
          &.-cart {
            a {
              display: inherit;
              padding-top: 15px;
              @include pc {
                display: flex;
                padding: 0 10%;
              }
            }
          }
          @include pc {
            @at-root _:lang(x)::-ms-backdrop, &.-cart {
              height: 93px;
            }
          }
        }
      }
      a {
        color: #000;
        text-decoration: none;
      }
    }
    &Item {
      height: 100%;
      @include pc {
        margin: 0 3%;
        width: 20%;
      }
      &.-current,
      &:hover {
        & > a,
        & > span.nav {
          @include pc {
            border-bottom: 2px solid #e60012;
            color: #e60012;
          }
          &:after {
            @include pc {
              background: url(/img/common/icon_arrow05.svg)no-repeat;
              background-size: 100% auto;
              transition: all 0.25s;
            }
          }
        }
      }
      &.-name {
        color: #888;
        @include pc {
          font-size: 12px;
          line-height: (17/12);
        }
      }
      &.-arrow {
        & > a,
        & > span.nav {
					@include sp{
						transition: transform 0.25s;
					}
          &:after {
            content: '';
						background: url(/img/common/icon_arrow03.svg)no-repeat;
						background-size: 100% auto;
            display: block;
            height: 6px;
            position: absolute;
            left: 50%;
            bottom: 12px;
						transition: transform 0.25s;
						transform: translateX(-50%) rotate(0deg);
						width: 10px;
						@include sp{
							background: none;
							transform: translateX(-50%) rotate(-45deg);
							width: 6px;
							border-left: 1px solid #000;
							border-bottom: 1px solid #000;
							backface-visibility: hidden;
						}
          }
        }
        &:hover {
          cursor: pointer;
          .l-header__menu {
            opacity: 1;
            visibility: visible;
						z-index: 100;
          }
        }
        &:not(:hover) {
          .l-header__menu {
            padding-top: 0;
            padding-bottom: 0;
          }
          .l-header__menuList {
            @include pc {
              opacity: 0;
              transform: translateY(40px);
            }
          }
        }
      }
      & > span.nav,
      & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.5;
        height: 100%;
        position: relative;
        text-align: center;
        @include pc {
          border-bottom: 2px solid transparent;
          padding: 0 10%;
          transition: all 0.25s;
          white-space: nowrap;
          width: 100%;
        }
        &:hover {}
      }
    }
  }
  &__cartNum {
    @include font-Montserrat_regular; 
    color: #fff;
    font-size: 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    width: 21px;
    height: 21px;
    line-height: 20px;
    @include pc {
      font-size: 11px;
      height: 22px;
      margin: 0 0 0 3px;
      width: 22px;
      line-height: 22px;

    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      background: #e60012;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      top: 50%;
      left: 50%;
      z-index: -1;
      top: 0;
      left: 0;
      @include pc {
        width: 22px;
        height: 22px;
      }
    }
  }
  &__menu {
    background: #333;
    color: #fff;
    padding: 30px 5% 0;
    position: absolute;
    top: 114px;
    left: 0;
    width: 100%;
    z-index: 99;
    @include pc {
      background: #ffe5e0;
      padding: 46px 4.2% 58px;
      opacity: 0;
      top: 5.813rem;
      transition: all 0.25s;
      visibility: hidden;
    }
    &.-info,
    &.-search {
      height: calc(100% - 114px);
      overflow-y: scroll;
      position: fixed;
      overflow: visible;
      overflow-scrolling: touch;
      -webkit-overflow-scrolling: touch;
    }
    &.-info {
      .l-header__navHead {
        padding: 17px 12% 16px 6%;
        &:after {
          content: none;
        }
      }
    }
    &.v {
      &-enter-active,
      &-leave-active {
        transition: opacity 0.2s;
      }
      &-enter,
      &-leave-to {
        opacity: 0;
      }
    }
    &Search {
      border-top: 2px solid #871b23;
      padding-top: 20px;
      padding-bottom: 22px;
      position: relative;
      @include pc {
        border-top: none;
        padding-top: 16px;
        padding-bottom: 37px;
        width: 48.8%;
      }
      &:before {
        content: '';
        background: #871b23;
        display: block;
        height: 2px;
        position: absolute;
        top: -6px;
        left: 0;
        width: 100%;
        @include pc {
          content: none;
        }
      }
      &__inner {
        position: relative;
      }
      input[type="search"] {
        @include font-noto_sans;
        background: #fff;
        box-shadow: none;
        color: #000;
        font-size: 13px;
        height: 48px;
        padding-left: 13px;
        width: 100%;
        @include pc {
          font-size: 15px;
        }
      }
      &__btn {
        background: #e60012;
        height: 32px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 32px;
        &:after {
          content: '';
          background: url(/img/common/icon_arrow04.svg)no-repeat;
          background-size: 100% auto;
          display: block;
          height: 17px;
          position: absolute;
          top: 50%;
          left: 50%;
          transition: all 0.25s;
          transform: translate(-50%, -50%);
          width: 11px;
        }
      }
    }
    &List {
      background: #393939;
      border-top: 2px solid #871b23;
      margin-bottom: 20px;
      @include pc {
        background: none;
        border-top: none;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        width: 100%;
				transition: opacity 0.3s cubic-bezier(.17, .84, .44, 1) 0.3s, transform 0.3s cubic-bezier(.17, .84, .44, 1) 0.3s;
      }
      .l-header__menuItem {
        &:first-child {
          border-top: none;
        }
      }
    }
    &Item {
      border-top: 2px solid #616161;
      @include pc {
        border-top: none;
        margin-right: 2.3%;
        position: relative;
        width: 31.8%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      &:after {
        @include pc {
          content: '';
          background: url(/img/common/line_dot.svg)repeat-x;
          display: block;
          height: 3px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
      a {
        color: #fff;
        text-decoration: none;
        @include pc {
          @include font-Montserrat;
          color: #000;
          display: block;
          font-size: 15px;
          letter-spacing: 0.16em;
          line-height: (23/15);
          padding-top: 10px;
          padding-bottom: 13px;
          position: relative;
          transition: all 0.25s;
        }
        &:after {
          @include pc {
            content: '';
            background: url(/img/common/icon_arrow02.svg)no-repeat;
            background-size: 100% auto;
            display: block;
            height: 16px;
            position: absolute;
            top: 14px;
            right: 3px;
            transition: all 0.25s;
            width: 10px;
          }
        }
        &:hover {
          @include pc {
            color: #e60012;
          }
          &:after {
            @include pc {
              right: -1px;
            }
          }
        }
      }
      span {
        color: #fff;
        text-decoration: none;
        @include pc {
          @include font-Montserrat;
          color: #000;
          display: block;
          font-size: 15px;
          letter-spacing: 0.16em;
          line-height: (23/15);
          padding-top: 10px;
          padding-bottom: 13px;
          position: relative;
          transition: all 0.25s;
        }
      }
    }
    .l-header__nav {
      &Head {
        @include font-noto_sans_regular;
        font-size: 15px;
        letter-spacing: 0.12em;
        line-height: (24/15);
        margin-bottom: 0;
        position: relative;
        &:after {
          content: '';
          background: url(/img/common/icon_arrow04.svg)no-repeat;
          background-size: 100% auto;
          display: block;
          height: 17px;
          position: absolute;
          top: 50%;
          right: 21px;
          transition: all 0.25s;
          transform: translateY(-50%);
          width: 11px;
          @include pc {
            content: none;
          }
        }
        &.js-accordion {
          padding: 17px 12% 16px 6%;
          &:after {
            right: 25px;
            transform: translateY(-50%) rotate(90deg);
          }
        }
        &:not(.js-accordion) {
          & + .l-header__navBody {
            display: block;
          }
        }
        &.-open {
          &:after {
            transform: translateY(-50%) rotate(-90deg);
          }
        }
        &.js-accordion:not(.-open) {
          & + .l-header__navBody {
            @media print,
            screen and (max-width: $point-pc - 1) {
              display: none;
            }
          }
        }
        a {
          display: block;
          padding: 17px 12% 16px 6%;
        }
      }
      &Body {
        padding-bottom: 6px;
      }
      &List {
        display: block;
        .l-header__navItem {
          &:nth-child(4) {
            margin-left: 0;
            padding-right: 6%;
          }
          &:nth-child(5) {
            margin: 0;
          }
        }
      }
      &Item {
        font-size: 12px;
        letter-spacing: 0.16em;
        padding: 0 6%;
        &.-none {
          margin-top: 6px;
          a {
            &:before {
              content: none;
            }
          }
        }
        &.-more{
          a{
            text-align: right;
          }
        }
        a,
        button {
          @include font-Montserrat;
          display: block;
          padding: 11px 15px 11px 0;
          position: relative;
          text-align: left;
          width: 100%;
          &:before {
            content: '';
            background: url(/img/common/line_dot_gray.svg)repeat-x;
            display: block;
            height: 3px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }
          &:after {
            content: '';
            background: url(/img/common/icon_arrow04.svg)no-repeat;
            background-size: 100% auto;
            display: block;
            height: 12px;
            position: absolute;
            top: 50%;
            right: 2px;
            transition: all 0.25s;
            transform: translateY(-50%);
            width: 8px;
            @include pc {
              content: none;
            }
          }
        }
      }
    }
    .p-mypage {
      &__info {
        border-top: 2px solid #871b23;
        position: relative;
        &:before {
          content: '';
          background: #871b23;
          display: block;
          height: 2px;
          position: absolute;
          top: -6px;
          left: 0;
          width: 100%;
        }
      }
    }
  }
	.l-container.-shopping & {
		:not(.l-header__siteLogo > * ) > * {
			display: none;
		}
		text-align: center;
		background: #e60012;
		height: 49px;
		margin-bottom: 0;
		@include pc {
			height: 90px;
		}
		.l-header__siteLogo {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			a {
				pointer-events: none;
			}
			img {
				width: 144px;
				@include pc {
					width: 195px;
				}
			}
		}
	}
}