@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

//★あとで消す
//.l-container {
//  position: relative;
//  &:before {
//    position: absolute;
//    width: 100%;
//    height: 10000px;
//    display: block;
//    content: '';
//    background-image: url(/img/dummy/sp_contact_LP.jpg);
//    background-size: 375px auto;
//    background-repeat: no-repeat;
//    background-position: center top;
//    background-position: center 25px;
//    opacity: 0.4;
//    z-index: -1;
//    top: 0;
//  }
//  .l-main {
//    min-height: 5000px;
//  }
//}

.l-lead {
  padding: 0 20px;
  @include pc {
    padding: 0;
  }
  p {
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 40px;
    @include pc {
      font-size: 16px;
      padding-top: 30px;
      padding-bottom: 50px;
      line-height: (29/16);
      text-align: center;
    }
  }
}

.l-localNav {
  padding: 20px 20px;
  @include pc{
    padding: 0;
  }
  &__select.c-select1{
    width: 100%;
    max-width: inherit;
    @include pc{
      display: none;
    }
  }
  &__list {
    @include pc {
      padding: 40px 3.15%; 
      margin: 0 auto;
      max-width: 1440px;
      flex-wrap: wrap;
      display: flex;
    }
  }
  &__item {
    width: calc(25% - 24px);
		@media (min-width: 980px) and (max-width: 1130px) {
			width: calc(25% - 10px);
		}
    &:not(:nth-child(4n+1)) {
      margin-left: 32px;
			@media (min-width: 980px) and (max-width: 1130px) {
				margin-left: 10px;
			}
    }
    &:nth-child(n+5) {
      margin-top: 15px;
    }
    a {
      background: #fff;
      font-size: 13px;
      text-decoration: none;
      display: block;
      height: 45px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22.5px;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 5px;
        background-image: url(/img/common/icon_arrow05.svg);
        background-size: 100% auto;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
				@media (min-width: 980px) and (max-width: 1130px) {
					right: 15px;
				}
      }
      @include pc{
        &:hover {
          color: #e60012;
        }
      }
			@media (min-width: 980px) and (max-width: 1130px) {
				font-size: 12px;
			}
    }
  }

}

.l-kv.-red {
  position: relative;
  margin: 0 auto;
  color: #fff;
  @include pc {
    background: url(/img/word/kv_bg.jpg)repeat-x top center;
    background-size: auto 100%;
    min-height: calc(550px/2);
  }
  img {
    width: 100%;
    height: auto;
    @include pc {
      max-width: 1200px;
      height: auto;
    }
  }
  .l-kv__inner {
    @include pc {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}

.p-words {
  padding: 0 0 40px;
  background: #fee5e0;
  @include pc {
    padding: 0 0 60px;
  }
  &__inner {
    //padding: 0 60px !important; //★あとで消す
  }
  .c-sec__inner {
    @include sp {
      margin: 0;
    }
  }
}

.p-word {
    background: #fff;
  padding: 30px 20px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  @include pc {
    padding: 60px 60px;
    border: 1px solid #d7beb9;
    flex-direction: row;
    justify-content: space-between;
  }
  &:first-child {
    margin-top: 0;
  }
  &__columns {
    display: flex;
    flex-direction: column;
    @include pc {
      flex-direction: row;
      flex-wrap: wrap;
    }
    &.-topNormal {
      margin-top: 30px;
      padding-top: 20px;
      border-top: 1px solid #b7bdbf;
      @include pc {
        border-top: none;
        padding-top: 0;
        margin-top: 40px;
      }
    }
    &.-table {
      margin-top: 20px;
      @include pc {
        margin-top: 35px;
      }
    }
  }
  &__column {
    &.-column2 {
      width: 100%;
      margin-bottom: 20px;
      @include pc {
        width: 50%;
      }
      &:nth-child(1) {
        @include pc {
          padding-right: 50px;
          max-width: 260px;
        }
      }
      &:nth-child(2) {
        @include pc {
          padding-left: 60px;
        }
      }
    }
    &.-column2free {
      margin-bottom: 10px;
      margin-top: 20px;
      &:nth-child(1) {
        margin-right: 50px;
      }
      &:nth-child(2) {}
    }
    &.-column3 {
      margin-top: 30px;
      @include pc {
        width: calc(33.3% - 20px);
        margin-top: 0;
        margin-left: 30px;
      }
      &:first-child {
        margin-top: 0;
        @include pc {
          margin-left: 0;
        }
      }
    }
    &.-line {

      @include pc {
        border-left: 1px solid #b7bdbf;
      }
    }
  }
  &__dl {

    dt {
      @include font-Montserrat_medium;
      font-size: 14px;
    }
    dd {
      @include font-noto_sans_regular;
      font-size: 15px;
    }
  }
  h2.p-word__title {
    margin-bottom: 0;

    @include font-Montserrat_regular;
    .p-word__titleNo {
      display: block;
      width: 50px;
      @include pc {
        width: 82px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .p-word__titleText {
      display: block;
      font-size: 21px;
      font-weight: normal;
      letter-spacing: 0.10em;
      line-height: (31/21);
      @include pc {
        margin-top: 16px;
        font-size: 32px;
        line-height: 1.2;
      }
      .red {
        @include font-Montserrat_semiBold;
        color: #e60012;
      }
    }
  }
  &__contents {
    @include pc {
      width: 55%;
      max-width: 630px;
    }
    &.-full {
      width: 100%;
      max-width: inherit;
    }
  }
  p.p-word__catch {
    font-size: 15px;
    color: red;
    margin-top: 10px;
    letter-spacing: 0.05em;
    line-height: (25/15);
    @include pc {
      font-size: 18px;
      margin-top: 40px;
      letter-spacing: 0.10em;
      line-height: (27/18);
    }
  }
  p.p-word__lead {
    font-size: 13px;
    margin-top: 10px;
    letter-spacing: 0.05em;
    line-height: (23/13);
    @include pc {
      font-size: 14px;
      letter-spacing: 0.10em;
      margin-top: 20px;
      line-height: (23/14);
    }
  }
  &__figure {
    margin-top: 15px;
    @include pc {
      margin-top: 0;
      display: flex;
      width: calc(50% - 30px);
      max-width: 570px;
      align-items: center;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .c-term {
    &__title {
      color: #007ac3;
      &Sub {
        font-size: 13px;
        display: block;
      }
      &Main {
        font-size: 16px;
        display: block;
      }

    }
    &__demerit {
      color: #888;
      margin-top: 10px;
      font-size: 14px;
      & + .c-term__text {
        margin-top: 0;
      }
    }
    &__text {
      margin-top: 10px;
      font-size: 14px;
      line-height: (23/14);
      letter-spacing: 0.10em;
    }
  }
}