@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-buy {
  &__head {
    @include font-noto_sans;
    background: #e60012;
    color: #fff;
    font-size: 13px;
    letter-spacing: 0.16em;
    padding: 15px 0;
    text-align: center;
  }
  &__body {
    background: #f7f7f7;
    padding: 30px 5.7% 25px;
    @include pc {
      padding: 40px;
    }
    .c-product {
      &Main {
        &__title {
          font-size: 18px;
          margin-bottom: 0;
          @include pc {
            font-size: 17px;
          }
        }
      }
      &Item {
        &__title {
          font-size: 18px;
          margin-top: 4px;
          margin-bottom: 25px;
          @include pc {
            font-size: 17px;
            margin-bottom: 28px;
          }
        }
      }
    }
    .p-form {
      background: none;
      padding: 0;
      margin: 0 auto;
      width: 100%;
      .-grid_row {
        margin-bottom: 10px;
        &__inner {
          @include font-noto_sans;
          font-size: 13px;
          letter-spacing: 0.16em;
          padding: 4px 10px;
          @include pc {
            font-size: 14px;
            padding: 6px 10px;
          }
        }
      }
      select {
        @include font-noto_sans;
        font-size: 13px;
        letter-spacing: 0.16em;
        padding: 14px 10px;
        @include pc {
          font-size: 14px;
          padding: 6px 10px;
        }
      }
      option {
        @include font-noto_sans;
        font-size: 13px;
        @include pc {
          font-size: 14px;
        }
      }
    }
    .c-btnWrap {
      margin: 0;
      padding-top: 4px;
      padding-bottom: 27px;
      position: relative;
      @include pc {
        padding-top: 31px;
        padding-bottom: 35px;
      }
      &:before {
        @include pc {
          content: '';
          background: url(/img/common/line_dot_gray.svg)repeat-x;
          display: block;
          height: 3px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }
      .c-btn {
        button {
          font-size: 12px;
          @include pc {
            font-size: 13px;
          }
        }
      }
    }
  }
  &__columns {
    padding-top: 24px;
    position: relative;
    @include pc {
      display: flex;
      padding-top: 0;
      padding-bottom: 36px;
    }
    &:before {
      content: '';
      background: url(/img/common/line_dot_gray.svg)repeat-x;
      display: block;
      height: 3px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include pc {
        content: none;
      }
    }
  }
  &__column {
    padding-bottom: 26px;
    @include pc {
      padding-bottom: 0;
      width: 50%;
    }
    &.-left {
      border-bottom: 1px solid #bfbfbf;
      @include pc {
        border-bottom: none;
        border-right: 1px solid #bfbfbf;
        padding-right: 4.5%;
      }
    }
    &.-right {
      padding-top: 27px;
      position: relative;
      @include pc {
        padding-top: 0;
        padding-left: 4.5%;
      }
    }
  }
  &__title {
    @include font-noto_sans_mideum;
    font-size: 15px;
    letter-spacing: 0.16em;
    margin-bottom: 14px;
    @include pc {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
  .c-checkbox {
    position: absolute;
    top: 26px;
    right: 10px;
    @include pc {
      top: 0;
    }
    &__label {
      @include font-noto_sans;
      font-size: 13px;
      @include pc {
        font-size: 14px;
      }
    }
  }
  .p-item {
    &__count {
      border-top: none;
      justify-content: flex-end;
      margin: 15px 0;
      padding-top: 0;
      width: 100%;
      @include pc {
        margin: 20px 0;
      }
    }
    &__amountText {
      font-size: 13px;
      @include pc {
        font-size: 15px;
      }
    }
    &__amountNumber {
      @include font-Montserrat_medium;
      color: #888;
    }
  }
  &__attention {
    @include font-noto_sans;
    color: #e60012;
    font-size: 11px;
    letter-spacing: 0.14em;
    line-height: (22/11);
    @include pc {
      font-size: 12px;
      line-height: (18/12);
      margin-bottom: 18px;
    }
  }
  &__notice {
    @include font-noto_sans;
    font-size: 11px;
    letter-spacing: 0.14em;
    line-height: (22/11);
    @include pc {
      font-size: 12px;
      line-height: (18/12);
    }
  }
}
