@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-product {
  margin-bottom: 40px;
  @include pc {
    margin-bottom: 56px;
  }
  .c-sec__inner {
    padding-top: 30px;
    padding-bottom: 30px;
    @include pc {
      padding: 4% 3.8%;
    }
  }
  &__head {
    background: #fee5e0;
    &__inner {
      @include pc {
        display: flex;
        flex: 1 0 auto;
        justify-content: space-between;
      }
      .c-product {
        .c-label {
          padding: 7px 17px;
          @include pc {
            padding: 8px 10px;
          }
          @include pcIE {
            padding: 8px 10px 3px;
          }
        }
      }
      .c-btnWrap {
        @include sp {
          justify-content: flex-end;
        }
        .c-btn {
          @include sp {
            width: 118px;
          }
          a {
            @include sp {
              height: 30px !important;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .c-product {
    border-color: #ebd1cc;
    padding: 9px 6% 7px;
    @include pc {
      display: flex;
      padding: 30px 0 37px;
      width: 71.9%;
    }
		.p-mypage__main.-favorite & {
			border-color: #bad3d6;
			margin-top: 40px;
			@include pc {
				margin-top: 60px;
			}
		}
    &.-wFull {
      @include pc {
        width: 100%;
        flex: 0 0 auto;
      }
    }
    &Main {
      padding-bottom: 0;
      position: relative;
      @include pc {
        padding: 0 3% 0 3.3%;
        width: 50%;
        max-width: 50%;
        flex-basis: 50%;
      }
      .c-logo {
        margin-top: -15px;
        @include pc {
          margin-top: 0;
        }
      }
      &__title {
        margin-top: 4px;
        margin-bottom: 0;
        @include pc {
          @include vw(22);
          margin-top: 0;
        }
        @media screen and (min-width: 1440px) and (min-width: 980px) {
          font-size: 21px;
        }
      }
      &__thumb {
        margin-bottom: 0;
        min-height: 170px;
        @media all and (-ms-high-contrast: none) {
          height: 170px;
        }
        @include pc {
          align-items: center;
          min-height: 237px;
          @media all and (-ms-high-contrast: none) {
            height: 237px;
          }
        }
        &Item {
          width: 100%;
          text-align: center;
        }
        img {
          @include pc {
            width: auto;
            max-height: 236px;
            max-width: calc(95% * 0.719);
            @media all and (-ms-high-contrast: none) {
              max-height: none;
            }
          }
          @include pcIE {
            max-width: 95%;
            max-height: 100%;
            width: auto;
            height: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .swiper-pagination {
        bottom: -4px;
				.p-mypage__main.-favorite & {
					display: none;
				}
      }
      .c-label {
        position: absolute;
        top: 107px;
        left: -8px;
        z-index: 10;
        @include pc {
          margin-bottom: 28px;
          position: static;
        }
				.p-mypage__main.-favorite & {
					padding: 7px 17px;
          @include pc {
            padding: 8px 10px;
          }
          @include pcIE {
            padding: 8px 10px 3px;
          }
				}
      }
      &__title {}
    }
    .c-productItem {
      background: #fff;
      padding: 0 0 18px;
      @include pc {
        padding: 0 3% 0 3.4%;
        flex-basis: 50%;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 auto;
        border-left: 1px solid #b7bdbf;
      }
      &:before {
        content: none;
      }
      &__inner {
        @include pc {
          padding-top: 70px;
          position: relative;
        }
      }
      @at-root .p-product .c-product .c-productItem__favWrap {
        min-height: 35px;
        @include pc {
          min-height: none;
        }
        &::before {
          position: relative;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 3px;
          content: '';
          background: url(/img/common/line_dot.svg) repeat-x;
          margin-top: 16px;
          margin-bottom: 16px;
          @include pc {
            content: none;
          }
        }
      }
      &__fav {
        top: 16px;
        z-index: +1;
        @include pc {
          top: 0;
        }
      }
      &__title {
        @include fz(13);
        padding-right: 0;
        margin-bottom: 16px;
        @include pc {
          @include vw(22);
          padding-top: 0;
          margin-top: 4px;
          margin-bottom: 16px;
          &::before {
            content: none;
          }
        }
        a {
          padding-left: 23px;
          &:before {
            @include pc {
              height: 20px;
              width: 13px;
            }
          }
        }
      }
      &__columns {
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 3px;
        @include pc {
          justify-content: flex-start;
          margin-bottom: 13px;
        }
      }
      &__column {
        width: auto;
        text-align: right;
        @include pc {
          text-align: left;
        }
      }
      &__sum {
        margin-left: 5px;
      }
      &__price {
        margin-bottom: 0;
        &Tax {
          @include sp {
            font-size: 13px;
          }
        }
      }
      &__feature {
        margin-left: 4px;
        margin-bottom: 9px;
        @include pc {
          margin-bottom: 15px;
        }
      }
      .c-tag {
        display: inline;
      }
      .c-icon__list {
        @include pc {
          margin: 0 0 51px 4px;
        }
      }
      .c-icon__item {
        margin-left: 0;
        width: 20%;
        img {
          width: 93%;
          @include pc {
            width: 85%;
          }
        }
      }
      .c-btnWrap {
        @include pc {
          padding-top: 30px;
          position: relative;
          &:before {
            content: '';
            background: url(/img/common/line_dot_gray.svg)repeat-x;
            display: block;
            height: 3px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }
  .c-productList {
    @include pc {
      flex-basis: 25.6%;
      width: 25.6%;
      max-width: 25.6%;
    }
    .c-productItem {
      background: #fff;
      border: 1px solid #ebd1cc;
      padding-left: 6%;
      padding-right: 6%;
      margin-top: 20px;
      @include pc {
        padding: 18px 7%;
        margin-top: 30px;
        &:first-of-type {
          margin-top: 0;
        }
      }
      &::before {
        display: none;
      }
      &__inner {
        @include pc {
          transform: translateY(50%);
        }
      }
      &__title {
        @include pc {
          padding-right: 0;
        }
      }
      .c-label {
        margin-bottom: 13px;
      }
      &__fav {
        top: 0;
        right: 0;
        @include pc {
          top: 20px;
          right: 6%;
        }
      }
      &__price {
        margin-bottom: 0;
        line-height: inherit;
        @include fz(25);
        @include pc {
          @include vw(30);
          margin-bottom: 15px;
        }
        &Tax {
          @include sp {
            font-size: 13px;
          }
        }
      }
      .c-btn {
        a {
          font-size: 11px;
          @include pc {
            font-size: 12px;
          }
        }
      }
    }
  }
  &Detail {
    padding: 25px 5% 0;
    @include pc {
      max-width: 1440px;
      padding: 56px 3.8% 0;
      box-sizing: border-box;
      margin: 0 auto;
    }
    &__head {
      margin-bottom: 10px;
      @include pc {
        margin-bottom: 30px;
      }
    }
    &__title {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.16em;
      margin-bottom: 0;
      @include pc {
        font-size: 18px;
      }
    }
    &__inner {
      padding-bottom: 25px;
      @include pc {
        display: flex;
        justify-content: space-between;
        flex: 0 0 auto;
        margin-bottom: 35px;
      }
    }
    &__text {
      margin-bottom: 27px;
      @include pc {
        flex: 1;
        margin-right: 4.3%;
        margin-bottom: 0;
      }
    }
    &__textInner {
      @include font-noto_sans;
      font-size: 13px;
      letter-spacing: 0.16em;
      line-height: (38/26);
      word-break: break-all;
      @include pc {
        font-size: 16px;
        padding-right: 25px;
        overflow-y: auto;
        letter-spacing: 0.14em;
        line-height: (57/32);
        height: 250px;
      }
    }
    &__link {
      font-size: 13px;
      margin-top: 10px;
      a {
        white-space: nowrap;
        color: #000;
        text-decoration: none;
        padding-bottom: 2px;
        border-bottom: 1px solid #000;
        transition: all 0.2s;
        &:hover {
          border-bottom: 1px solid #fff;
        }
      }
    }
    &__feature {
      @include pc {
        width: 25.6%;
        flex-basis: 25.6%;
      }
      .c-productItem__feature {
        margin-bottom: 30px;
        justify-content: flex-start;
        @include pc {
          margin-bottom: 25px;
        }
      }
      .c-icon__list {
        margin-bottom: 17px;
        flex-wrap: wrap;
        justify-content: flex-start;
          margin-left: -5%;
        @include pc {
          margin-top: 3px;
          margin-bottom: 30px;
          margin-left: inherit;
        }
        .c-icon__item {
          width: calc(20% - 3%);
          margin-left: 3%;
          @include pc {
            &:first-child {
              margin-left: 0;
            }
          }
          &:nth-of-type(5n+6) {
            margin-left: 0;
          }
          &:nth-of-type(n+6) {
            margin-top: 5px;
          }
        }
      }
      &List {
        font-size: 13px;
        padding-top: 25px;
        position: relative;
        @include pc {
          padding-top: 20px;
        }
        &:before {
          content: '';
          background: url(/img/common/line_dot_gray.svg)repeat-x;
          display: block;
          height: 3px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }
      &Item {
        letter-spacing: 0.16em;
        line-height: (47.4/26);
        @include pc {
          line-height: (50.6/26);
        }
      }
    }
  }
  .c-table {
    margin-bottom: 30px;
    @include pc {
      width: 31.4%;
      flex-basis: 31.4%;
      margin-top: 3px;
      margin-bottom: 0;
      margin-right: 4.8%;
    }
    &__inner {
      @include font-Montserrat_medium;
      font-size: 13px;
      letter-spacing: 0.06em;
      @include pc {
        font-size: 14px;
      }
    }
    &__table {
      th {
        width: 33%;
        @include pc {
          width: 26%;
        }
      }
      td {
        padding: 12px 0 10px;
        @include pc {
          padding: 12px 0 8px;
        }
      }
    }
  }
  &Info {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    &__head {
      position: relative;
      padding: 25px 0 20px;
      @include pc {
        cursor: pointer;
        padding: 55px 0;
      }
      &:before {
        content: '';
        background: url(/img/common/icon_arrow03.svg)no-repeat;
        background-size: 100% auto;
        display: block;
        height: 6px;
        position: absolute;
        right: 8px;
        top: 25px;
        transition: all 0.25s;
        transform: translateX(-50%) rotate(180deg);
        width: 10px;
        @include pc {
          right: 20px;
          top: 47px;
        }
      }
      &:after {
        content: '';
        background: url(/img/common/icon_arrow03.svg)no-repeat;
        background-size: 100% auto;
        display: block;
        height: 6px;
        position: absolute;
        right: 8px;
        bottom: 25px;
        transition: all 0.25s;
        transform: translateX(-50%);
        width: 10px;
        @include pc {
          right: 20px;
          bottom: 47px;
        }
      }
      &.-open {
        &:before {
          transform: translateX(-50%) rotate(0deg);
        }
        &:after {
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }
    &__title {
      font-weight: 500;
      letter-spacing: 0.16em;
      @include pc {
        font-size: 18px;
      }
    }
    &__inner {
      text-align: center;
      img {
        height: auto;
        max-width: 100%;
      }
    }
    &__close {
      .js-accordion__close {
        display: block;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        padding: 20px 0;
        margin-top: 20px;
        &:before {
          content: '';
          background: url(/img/common/icon_arrow03.svg)no-repeat;
          background-size: 100% auto;
          display: block;
          height: 6px;
          position: absolute;
          left: 50%;
          top: 50%;
          transition: all 0.25s;
          transform: translateX(-50%) rotate(180deg);
          width: 10px;
        }
        @include pc {
          &:hover::before {
            top: 40%;
          }
        }
      }
    }
  }
}