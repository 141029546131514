@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};
//@include onlyTB{};
//@include onlyPC{};
/* */

html {
  font-size: 16px;
  @include font-noto_sans_regular;
  @include sp {
    font-size: 62.5%;
  }
  @include wide {
    font-size: 16px;
  }
}

body {
  margin: 0;
  line-height: 1.6;
  color: #333;
}

html > body {
  @include pc {
    min-width: 980px;
  }
}

a {
  color: #333;
  outline: none;
  -webkit-touch-callout: none;
}

a:hover {
  text-decoration: none;
}

a:focus,
*:focus {
  outline: none;
}

img {
  vertical-align: top;
  border: none;
  border-style: none;
}

ol,
ul,
li {
  list-style: none;
}

section {
  margin-bottom: 65px;
  @include pc {
    margin-bottom: 90px;
  }
}

h1 {
  margin-top: 80px;
  margin-bottom: 30px;
  @include pc {
    margin-top: 125px;
    margin-bottom: 40px;
  }
}
h2 {
  margin-bottom: 30px;
}

p {
  font-size: 13px;
  @include pc {
    font-size: 14px;
  }
}

p + p {
  margin-top: 30px;
  @include pc {
    margin-top: 40px;
  }
}

.js-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include sp {
    left: auto;
  }
  &.-show {
    .js-modal__overlay {
      transition: opacity 0.2s;
      opacity: 0.7;
    }
    .js-modal__content {
      @include sp {
				transition: transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				transform: translateX(0);
      }
    }
  }
  &:not(.-show) {
		transition: all 0.5s;
    visibility: hidden;
    opacity: 0;
  }
  &2 {
    .p-form {
      .-grid_row {
        @include sp {
          margin-bottom: 20px;
        }
        &:first-child {
          @include sp {
            margin-bottom: 30px;
          }
        }
      }
    }
    .c-buy {
      .p-item__count {
        @include sp {
          margin-top: 26px;
        }
      }
    }
  }
  &4 {
    .js-modal__content {
      @include pc {
        width: 44%;
      }
    }
    .c-buy__body {
      .c-productItem__title {
        margin-bottom: 0;
        padding-bottom: 27px;
        position: relative;
        @include pc {
          margin-bottom: 69px;
        }
        &:before {
          content: '';
          background: url(/img/common/line_dot_gray.svg)repeat-x;
          display: block;
          height: 3px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          @include pc {
            content: none;
          }
        }
      }
      .p-item__count {
        margin-bottom: 28px;
        @include sp {
          margin-top: 23px;
        }
        @include pc {
          margin-bottom: 26px;
        }
      }
      .c-btnWrap {
        padding-bottom: 0;
        .c-btn {
          @include sp {
            width: 100%;
          }
        }
      }
    }
  }
  &.-verA {
    .c-buy {
      &__columns {
        padding-bottom: 0;
      }
      &__column {
        &.-left {
          @include sp {
            border-bottom: none;
          }
        }
        &.-right {
          @include sp {
            padding-top: 0;
          }
        }
      }
      .c-checkbox {
        @include sp {
          top: 0;
        }
      }
      .p-item__count {
        margin: 0 0 26px;
        @include pc {
          justify-content: center;
          margin: 20px 0;
        }
      }
      .p-item__amount {
        @include pc {
          margin-right: 40px;
        }
      }
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    justify-content: center;
    overflow: auto;
    transition: opacity 0.5s;
    opacity: 0;
  }
  &__contentWrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
		overflow: auto;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 0;
    @include sp {
      position: absolute;
      text-align: right;
			overflow-y: auto;
    	overflow-x: hidden;
			-webkit-overflow-scrolling: touch;
    }
    &::after {
      display: inline-block;
      height: 100%;
      margin-left: -.05em;
      content: "";
    }
  }
  &__content {
    position: relative;
    display: inline-block;
    width: 94.6%;
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 1000px;
    background-color: #fff;
    text-align: left;
    vertical-align: middle;
    @include sp {
      margin-top: 0;
      transition: transform 0.5s ease-out;
			-webkit-transition: -webkit-transform 0.5s ease-out;
			transform: translateX(100%);
    }
    @include pc {
      width: 66%;
    }
    &.-full{
      @include sp{
        right: inherit;
      }
      width: 100%;
      max-width: inherit;
    }
  }
  &__closeBtn {
    background: url(/img/common/icon_close.png)no-repeat 50%;
    background-size: 15px auto;
    display: block;
    height: 30px;
    position: absolute;
    top: 8px;
    right: 11px;
    text-indent:-9999px;
    width: 30px;
		z-index: +1;
  }
}
