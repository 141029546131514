@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-tab {
  border-bottom: 1px solid #e7eeef;
  padding: 0 4.8%;
  margin-bottom: 35px;
  margin-left: calc(((100vw - 100%)/2)*-1);
  margin-right: calc(((100vw - 100%)/2)*-1);
  font-size: 0;
  @include pc{
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
  }
  &__item {
    display: inline-block;
    width: 50%;
    a {
      display: block;
      text-decoration: none;
      span {
        display: block;
        line-height: 1;
        padding: 13px 5px;
        text-align: center;
        border-top: 1px solid #e7eeef;
        border-right: 1px solid #e7eeef;
        border-left: 1px solid #e7eeef;
        font-size: 13px;
        transition: all 0.2s;
        letter-spacing: 0.2em;
        @include pc{
          font-size: 15px;
          padding: 13px;
        }
      }
      @include pc{
        &:hover {
          span {
            color: #e60012;
          }
        }
      }
    }
    &:first-of-type {
      a span {
        border-right: none;
      }
    }
    &.-current {
      a span {
        background: #e7eeef;
      }
    }
  }
}
