@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-container {
	padding-top: 155px;
	@include pc{
		padding-top: 0;
		&::after {
			position: fixed;
			display: block;
			content: "";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(#000, 0.2);
			transition: all 0.3s ease;
		}
	}
  &:not(.-mask)::after {
    visibility: hidden;
    opacity: 0;
  }
	&.-shopping {
		padding-top: 89px;
		@include pc{
			padding-top: 0;
		}
	}
}
