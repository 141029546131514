@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-text {
  &.-mon {
    @include font-Montserrat_medium;
    line-height: 1.4;
    @include pc {
      line-height: 1;
    }
  }
  &.-red {
    color: #e60012;
  }
  &.-line {
    text-decoration: line-through;
  }
  &.-note {
    font-size: 12px;
    @include pc {
      font-size: 13px;
    }
  }
}
