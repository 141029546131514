@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

/* Common */
  #amazon_info .p-static__title.-inside {
    margin-bottom: 5px;
  }
  #amazon_info .p-static__text {
    margin: 0 0 10px;
  }
  /* SP and TB */
  #amazon_info,
  #consentWidgetDiv {
    width: 100%;
    max-width: 500px;
  }
  #amazon_info {
    margin: auto;
  }
  #consentWidgetDiv {
    margin: 40px auto 0;
  }
  .widget-container,
  .widget-container iframe {
    width: 100% !important;
  }
  /* PC */
  @media only screen and (min-width: 980px) {
    #amazon_info {
      max-width: none;
    }
    #amazon_address,
    #amazon_payment {
      width: calc(50% - 40px);
      margin: 0 20px;
      float: left;
    }
    #amazon_info::after {
      content: '';
      display: block;
      clear: both;
    }
  }