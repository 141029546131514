@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-bunner {
  margin-bottom: 36px;
  &__list {
    @include pc {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &__item {
    border: 1px solid #bfbfbf;
    margin-bottom: 15px;
    @include pc {
      margin-bottom: 32px;
      overflow: hidden;
      width: calc(50% - 16px);
    }
    a {
      &:hover {
        img {
          @include pc {
            transform: scale(1.05);
          }
        }
      }
    }
    img {
      transition: all 0.2s;
      width: 100%;
    }
  }
}
