@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-loading{
   z-index: 9999;
}

.l-header {
  z-index: 1000;
}

.l-container {
  &::after {
    z-index: 999;
  }
}

.l-footer {
  z-index: 998;
}

.js-modal {
  z-index: 2000;
}
