@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-kv {
  margin-top: 40px;
  margin-bottom: 40px;
  @include pc {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  & + .p-static {
    margin-top: 0;
  }
  &__img {
    img {
      width: 100%;
    }
  }
  &__body {
    margin: 0 5%;
    text-align: center;
    @include pc {
      margin: 0 auto;
      padding: 0 3.15% 10px;
      max-width: 1440px;
    }
  }
  &__title {
    @include font-noto_sans;
    @include fz(21);
    letter-spacing: 0.14em;
    margin-top: 24px;
    margin-bottom: 17px;
    @include pc {
      @include vw(32);
      margin-top: 50px;
      margin-bottom: 25px;
    }
  }
  &__read {
    @include font-noto_sans;
    @include fz(16);
    line-height: (28/16);
    letter-spacing: 0.14em;
    @include sp {
      text-align: left;
    }
    @include pc {
      font-size: 16px;
    }
    .red {
      color: #e60012;
    }
  }
}
