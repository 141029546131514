@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

//.l-container {
//  position: relative;
//  &:before {
//    position: absolute;
//    top: 0;
//    z-index: -1;
//    display: block;
//    width: 100%;
//    height: 10000px;
//    content: '';
//    background-image: url(/img/dummy/99_form.jpg);
//    background-repeat: no-repeat;
//    background-position: center top;
//    background-position: center -660px;
//    opacity: 0.4;
//  }
//}
// @include container;
//  margin: 0 auto;
//  padding-left: 20px;
//  padding-right: 20px;
//  box-sizing: border-box;
//  font-size: 16px;
//  line-height: 1.4;
//  color: #525263;
//  width: 100%;
//  max-width: 1130px;
.__parts {
  padding: 0 0 80px;
  @include pc {
    padding: 0 0 120px;
    max-width: 950px;
    margin: 0 auto;
  }
}

/*お届け先*/

.p-address {
  padding: 0 20px;
  margin: 0 -5.6%;
  background: rgba(#f7f7f7, 1);
  @include font-noto_sans_regular;
  @include pc {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 950px;
  }
  &__inner {
    padding: 30px 0;
  }
  &__wrap {
    display: block;
    + .p-address__wrap {
      margin-top: 10px;
    }
  }
  &__column {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 15px 20px;
    flex-wrap: wrap;
    @include pc {
      flex-wrap: nowrap;
      padding: 20px 40px 20px 20px;
    }
    + .p-address__column {
      margin-top: 10px;
    }
  }
  &__check {
    padding-right: 20px;
    font-size: 18px;
    @include font-Montserrat_medium;
    color: #bfbfbf;
  }
  &__contents {
    flex-grow: 1;
    @include font-noto_sans_regular;
    width: calc(100% - 40px);
    @include pc {
      width: auto;
    }
  }
  &__name {
    font-size: 13px;

    @include pc {
      font-size: 14px;
    }
  }
  &__text {
    font-size: 12px;
    @include sp {
      margin-top: 5px;
    }
    @include pc {
      font-size: 13px;
    }
  }
  &__link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include sp {
      margin-top: 5px;
    }
    @include pc {
      width: 245px;
    }
    a {
      padding-bottom: 2px;
      color: #000;
      text-decoration: none;
      white-space: nowrap;
      border-bottom: 1px solid #000;
      transition: 0.2s ease-in-out;
      &:hover {
        border-bottom: 1px solid #fff;
      }
    }
    &List {}
    &Item {
      display: inline;
      font-size: 12px;
      @include pc {
        font-size: 13px;
      }
    }
    .p-address__linkItem + .p-address__linkItem {
      margin-left: 15px;
      @include pc {
        margin-left: 20px;
      }
    }
  }
  &__btnWrap {
    display: flex;
    @include sp {
      justify-content: center;
    }
    @include tb {
      justify-content: flex-end;
    }
    @include pc {
      justify-content: flex-end;
    }
    &.c-btnWrap {
      margin: 20px auto 0;
    }
  }
    .error {
      @include font-noto_sans_bold;
      color: #e60012;
      margin-top: 8px;
      font-size: 11px;
      @include pc {
        font-size: 12px;
      }
    }
}

/**フォーム**/

.p-form {
  padding: 0 20px;
  margin: 30px -5.6%;
  background: rgba(#f7f7f7, 1);
  @include font-noto_sans_regular;
  @include pc {
    margin: 40px auto;
    padding: 10px 40px;
    max-width: 950px;
  }
  a {
    color: #e60012;
  }
  &__body {
    padding-top: 10px;
  }
  &__column {}
  .-grid_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include sp {
      flex-direction: column;
      > :nth-of-type(n+2) {
        margin-top: 10px;
      }
    }
    &.-sp_row {
      @include sp {
        flex-direction: row;
      }
      .-grid_row__w50 {
        width: calc(50% - 10px);
        margin-top: 0;
				& > .note {
					white-space: nowrap;
				}
      }
    }
    &__w100 {
      width: 100%;
    }
    &__w55 {
      width: calc(55% - 10px);
      @include sp {
        width: 100%;
      }
    }
    &__w50 {
      width: calc(50% - 10px);
      @include sp {
        width: 100%;
      }
    }
    &__w45 {
      width: calc(45% - 10px);
      @include sp {
        width: calc(100% - 10px);
        &.titleset {
          padding-left: 10px;
        }
      }
    }
  }
  .-grid_column {
    display: flex;
    flex-direction: column;
    &__w50 {
      width: 100%;
      @include pc {
        width: 50%;
      }
      &.-sp_w50 {
        @include sp {
          width: 50%;
        }
      }
    }
    &__w100 {}
    > :nth-of-type(n+2) {
      margin-top: 10px;
    }
  }
  &__dl {
    display: flex;
    flex-direction: column;
    padding: 26px 0;
    @include pc {
      flex-direction: row;
      padding: 30px 0;
    }
    + dl {
      border-top: 1px solid #bfbfbf;
    }
    dt {
      font-size: 13px;
      letter-spacing: 0.160em;
      padding-bottom: 10px;
      br.pc{
        display: none;
      }
      @include pc {
        font-size: 14px;
        width: 30%;
        min-height: 35px;
        padding-top: 8px;
        padding-bottom: 0;
        br.pc{
          display: block;
        }
      }
      .must {
        font-size: 12px;
        color: #e60012;
        margin-left: 6px;
      }
    }
    dd {
      font-size: 13px;
      @include pc {
        font-size: 14px;
        width: 70%;
        min-height: 35px;
      }
      .c-radio {
        line-height: 2;
        @include pc {
          line-height: 1;
          min-height: 35px;
        }
      }
      .countPrice{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        &__count{
          padding-top: 0;
          width: 90px;
          min-height: 0;
        }
        &__price{
          min-height: 0;
          &Price{
            color: #e60012;
          }
        }
      }
      .listDot{
        &__item{
          position: relative;
          padding-left: 1em;
          margin-bottom: 0.5em;
          &:before{
            content: "・";
            position: absolute;
            top: 0;
            left: 0;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .listAsterisk{
        &__item{
          position: relative;
          padding-left: 1em;
          margin-bottom: 0.5em;
          &:before{
            content: "＊";
            position: absolute;
            top: 0;
            left: 0;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    &.-column {
      flex-direction: column !important;
      + dl {
        border-top: 1px solid #bfbfbf;
      }
      dt {
        width: 100%;
        padding-bottom: 10px !important;
        font-size: 13px;
        @include pc {
          font-size: 14px;
        }
      }
      dd {
        width: 100%;
        font-size: 13px;
        @include pc {
          font-size: 14px;
        }
      }
    }
    .titleset {
      display: flex;
      align-items: center;
      .titleset_title {
        margin-right: 10px;
        font-size: 13px;
        white-space: nowrap;
        @include sp {
          width: 7em;
        }
        @include pc {
          font-size: 14px;
        }
        &.-zip {
          @include sp {
            width: inherit;
          }
        }
      }
      .slink {
        width: inherit;
        flex-grow: 1;
      }
    }
    .columns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @include pc {
        width: 50%;
      }
      &.-phone {
        justify-content: flex-start;
        width: 100%;
        @include pc {
          width: 80%;
        }
        .c-input {
          margin: 0 2%;
          width: 30%;
          &:first-of-type {
            margin-left: 0;
          }
          &:nth-of-type(3) {
            margin-right: 0;
          }
        }
      }
      &.-zip {
        justify-content: flex-start;
        width: 100%;
        @include pc {
          width: 100%;
        }
        .c-input {
          margin: 0 2%;
          width: 50%;
          &:first-of-type {
            margin-left: 0;
          }
          &:nth-of-type(2) {
            margin-right: 0;
          }
        }
      }
      .c-select2 {
        width: 26%;
        &:before {
          right: 10px;
        }
      }
    }
    .columns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @include pc {
        width: 80%;
      }
      &.-col2 {
        width: 80%;
        @include sp {
          width: 100%;
        }
        .c-select2 {
          width: 35%;
        }
      }
      .c-select2 {
        width: 26%;
        &:before {
          right: 10px;
        }
      }
    }
    .text {
      @include pc {
        padding-top: 8px;
      }
    }
    .text + .text{
      padding-top: 8px;
    }
    .radio {
      display: flex;
      flex-wrap: wrap;
      @include sp {
        justify-content: space-between;
      }
      .radio_item + .radio_item {
        @include sp {
          min-height: 28px;
        }
        @include pc {
          margin-left: 10px;
        }
      }
      &_item {
        @include sp {
          width: 50%;
        }
      }
    }
    .note {
      font-size: 11px;
      color: #888;
      margin-top: 8px;
      @include pc {
        font-size: 12px;
      }
    }
    .error {
      @include font-noto_sans_bold;
      color: #e60012;
      margin-top: 8px;
      font-size: 11px;
      @include pc {
        font-size: 12px;
      }
    }
    .btnText {
      font-size: 12px;
      color: #000;
      margin-top: 8px;
      @include sp {}
      @include pc {
        text-align: center;
      }
    }
    .btnWrap {
      margin-top: 20px;
    }
    &.-zip {
      .-grid_row.-zip {
        color: tomato;
        padding-left: calc(1em + 10px);
      }
      .c-btn a {
        @include sp {
          font-size: 11px;
        }
      }
      .p-form a {
        @include font-noto_sans;
      }
    }
  }
}

//☆ラジオ選択エリア START
.p-address__check {
  input[type=radio] {
    display: none;
  }
  .c-radioarea {
    position: relative;
    height: 20px;
    width: 20px;
    display: flex;
  }
  input[type=radio] + .c-radioarea {
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      border: 1px solid #ddd;
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }
  input[type=radio]:checked + .c-radioarea {
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      background: #e60012;
      border-radius: 50%;
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
} //☆ラジオ選択エリア END
.p-address__inner + .p-address__inner,
.p-address__inner + .p-form__dl,
.p-form__dl + .p-address__inner {
  border-top: 1px solid #bfbfbf;
}