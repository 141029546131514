@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-checkboxWrap {
  margin: 30px auto;
  @include pc {
    margin: 40px auto;
  }
  &.-w950 {
    @include pc {
      max-width: 950px;
    }
  }
}

.c-checkbox + .c-checkbox {
  margin-top: 20px;
  @include pc {
    margin-top: 15px;
  }
}

.c-checkbox {
  input[type=checkbox] {
    display: none;
    margin: 0;
    &:checked + .c-checkbox__label:before {
      background: url(/img/common/icon_cheack_on.svg)no-repeat;
      background-size: 100% auto;
    }
  }

  .c-checkbox__label {
    cursor: pointer;
    font-size: 13px;
    display: inline-block;
    line-height: 1;
    margin-right: -13px;
    position: relative;
    padding: 0 0 0 28px;
    @include pc {
      font-size: 14px;
    }
    &:before {
      content: "";
      background: url(/img/common/icon_cheack.svg)no-repeat;
      background-size: 100% auto;
      box-sizing: border-box;
      display: block;
      height: 13px;
      margin-top: -6px;
      position: absolute;
      top: 50%;
      left: 1px;
      transition: all 0.2s;
      width: 16px;
      @include pc {
        left: 3px;
      }
    }
  }
  &__note {
    font-size: 12px;
    padding-left: 28px;
    color: #888;
    @include pc {
      font-size: 13px;
    }
  }
}
