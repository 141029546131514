@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-input {
  width: 100%;
  input[type="text"],
  input[type="tel"],
  input[type="number"],
  input[type="password"],
  input[type="email"] {
    background-color: #fff;
    min-height: 46px;
    padding: 6px 10px;
    font-size: 14px;
    width: 100%;
    border-radius: 0;
    @include font-noto_sans_regular;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    @include pc{min-height: 34px;}
    @media all and (-ms-high-contrast: none) {
      padding: 9px 10px 6px;
      min-height: 34px;
    }
  }
  input::placeholder {
    color: #ccc;
  }
}