@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-tag {
  border-radius: 20px;
  font-size: 10px;
  letter-spacing: 0.16em;
  margin-right: 4px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0 4px;
  position: relative;
  @media all and (-ms-high-contrast: none) {
    padding: 3px 4px 0;
  }
  @include pc {
    clear: both;
    float: left;
    margin-right: 0;
    padding: 1px 6px;
  }
  &.-red {
    @include font-noto_sans;
    border: 1px solid #ebd1cc;
    color: #e60012;
    @media all and (-ms-high-contrast: none) {
      padding: 2px 4px;
    }
  }
  &.-gray {
    @include font-Montserrat_medium;
    border: 1px solid #b5b5b5;
    color: #333;
  }
  &.-notes {
    &:after {
      content: '※';
      top: -4px;
      right: -15px;
    }
  }
  &List {
    display: flex;
    flex-wrap: wrap;
    @include pc {
      display: block;
      margin-bottom: 6px;
      @include clearfix;
    }
  }
}
