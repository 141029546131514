@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-textarea {
  width: 100%;
  textarea {
    background-color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    width: 100%;
    min-height: 100px;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    @include font-noto_sans_regular;
    @media all and (-ms-high-contrast: none) {
      padding: 13px 20px 10px;
    }
  }
  textarea::placeholder {
    color: #ccc;
  }
  &.-h300{
    min-height: 300px;
  }
}