@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

/**カート**/

.p-cart {
  margin: 0 auto 40px;
  max-width: 600px;
  @include pc {
    margin-bottom: 65px;
    max-width: 950px;
  }
  &__title {
    @include lhCrop(28/18);
    font-size: 18px;
    letter-spacing: 0.26em;
    line-height: (28/18);
    @include font-noto_sans_mideum;
  }
  &__btnWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    @include pc {
      margin-top: 40px;
    }
    &Inner {
      width: 100%;
      @include pc {
        width: 50%;
      }
    }
    &Btn {
      margin-top: 10px;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    &Note {
      font-size: 12px;
      text-align: center;
      margin-top: 8px;
      letter-spacing: 0.16em;
      @include font-noto_sans_mideum;
    }
  }
  &__total {
    display: flex;
    justify-content: flex-end;
    padding-top: 23px;
    letter-spacing: 0.16em;
    &Wrap {
      display: flex;
      border-bottom: 2px solid #b7bdbf;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 14px;
      @include pc {
        width: 50%;
      }
    }
    &Title {
      font-size: 14px;
      letter-spacing: 0.16em;
    }
    &Price {
      @include font-Montserrat_semiBold;
      color: #e60012;
      font-size: 25px;
      letter-spacing: 0.06em;
      line-height: 1.1;
      text-align: right;
      @include pc {
        font-size: 33px;
      }
      &Text {
        @include font-noto_sans;
        font-size: 33px;
      }
      &Yen {
        @include font-Montserrat_medium;
        font-size: 15px;
        letter-spacing: 0.16em;
        margin-right: 3px;
      }
      &Tax {
        @include font-Montserrat_medium;
        font-size: 15px;
        margin-left: 3px;
      }
    }
    &.-small {
      position: relative;
      &:before {
        width: 100%;
        opacity: 0.5;
        position: absolute;
        right: 0;
        display: block;
        height: 3px;
        content: '';
        bottom: 0;
        background: url(/img/common/line_dot.svg) repeat-x;
        @include pc {
          width: 50%;
        }
      }
      .p-cart__totalWrap {
        border-bottom: none;
      }
      .p-cart__totalTitle {
        font-size: 14px;
      }
      .p-cart__totalPrice {
        font-size: 18px;
      }
      .p-p-cart__totalPriceYen {}
      .p-p-cart__totalPriceTax {}
    }
  }
}

.p-item {
  border: 1px solid #bad3d6;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 40px;
  background: #fff;
  @include pc {
    flex-direction: row;
    padding: 18px 20px 18px 30px;
  }
  + .p-item {
    margin-top: 20px;
  }
  &__thumb {
    position: relative;
    width: 100%;
    min-height: (500px/2);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 220px;
    @include pc {
      width: 270px;
      margin-right: 20px;
    }
    img {
      width: auto;
      max-width: 60%;
      max-height: 150px;
      @media all and (-ms-high-contrast: none) {
        max-width: none;
        max-height: none;
        width: 80%;
      }
      @include pc {
        max-width: 80%;
        max-height: 200px;
        transition: all 0.2s;
        @media all and (-ms-high-contrast: none) {
          max-width: none;
          max-height: none;
          width: 80%;
        }
      }
    }
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        img {
          @include pc {
            transform: scale(1.05);
          }
        }
      }
    }
  }
  &__label {
    position: absolute;
    @include font-noto_sans_mideum;
    left: 0;
    top: 0;
    display: flex;
    @include pc {
      top: 11px;
    }
    .c-tag {
      padding: 3px 9px;
      font-size: 13px;

      @include pc {
        font-size: 12px;
      }
      @media all and (-ms-high-contrast: none) {
        padding: 6px 9px 3px;
      }
    }
  }
  &__contents {
    @include pc {
      border-left: 1px solid #bfbfbf;
      width: 343px;
      padding: 28px 30px;
      @at-root .p-mypage__main.-favorite & {
        width: 543px;
      }
    }
  }
  &__title {
    margin-bottom: 8px;
    font-size: 15px;
    letter-spacing: 0.016em;
    padding-left: 16px;
    position: relative;
    @include font-Montserrat_semiBold;
    @include pc {
      margin-bottom: 16px;
      padding-left: 20px;
      font-size: 14px;
    }
    a {
      text-decoration: none;
      color: #000;
      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 8px;
        height: 16px;
        margin-right: 10px;
        content: '';
        background: url(/img/common/icon_arrow02.svg) no-repeat;
        background-size: 100% auto;
        transition: .3s ease-in-out;
        transform: translateY(-50%);
        @include pc {
          width: 12px;
          height: 20px;
        }
      }
      @include pc {
        &:hover {
          color: #e60012;
          &:before {
            left: 5px;
          }
        }
      }
    }
  }
  &__spec {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    padding: 0 0 17px;
    position: relative;
    letter-spacing: 0.16em;
    @include font-Montserrat_semiBold;
    justify-content: space-between;
    @include pc {
      padding: 17px 20px;
      font-size: 13px;
    }
    &:before {
      opacity: 0.5;
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
      content: '';
    }
    &:before {
      top: 0;
      @include pc {
        background: url(/img/common/line_dot.svg) repeat-x;
      }
    }
		&:empty {
			padding: 0;
			&:before {
				content: none;
			}
		}
  }
  &__specDl {
    width: calc(50% - 18px);
  }
  &__specDt {
    @include font-noto_sans;

  }
  &__specDd {
    margin-top: 3px;
  }
  &__price {
    padding-top: 13px;
    @include font-Montserrat_semiBold;
    color: #e60012;
    font-size: 25px;
    letter-spacing: 0.06em;
    line-height: 1.1;
    text-align: right;
    position: relative;
    padding-bottom: 30px;
    @include pc {
      font-size: 33px;
    }
    &:before {
      opacity: 0.5;
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
      content: '';
      background: url(/img/common/line_dot.svg) repeat-x;
    }
    &:before {
      top: 0;
    }
    &Text {
      @include font-noto_sans;
      font-size: 33px;
      text-align: right;
    }
    &Yen {
      @include font-Montserrat_medium;
      font-size: 15px;
      letter-spacing: 0.16em;
      margin-right: 3px;
    }
  }
  &__attention {
    text-align: left;
  }
  &__count {
    position: relative;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #bfbfbf;
    padding-top: 37px;
    @include pc {
      width: 260px;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      border-top: none;
      @at-root .p-mypage__main.-favorite & {
        width: 60px;
      }
    }
  }
  &__delete {
    position: absolute;
    font-size: 13px;
    left: 0;
    bottom: 0;
    @include pc {
      left: auto;
      right: 0;
      top: 0;
    }
    a {
      white-space: nowrap;
      color: #000;
      text-decoration: none;
      padding-bottom: 2px;
      border-bottom: 1px solid #000;
      transition: 0.2s ease-in-out;
      &:hover {
        border-bottom: 1px solid #fff;
      }
    }
  }
  &__amount {
    display: flex;
    height: 35px;
    align-items: center;
    @include pc {
      min-height: 26px;
      width: 138px;
    }
    &Text {
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      letter-spacing: 0.016em;
      @include pc {
        height: 26px;
      }
    }
    &Minus {
      background: #b3b9bb;
    }
    &Number {
      width: 42px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      background: #fff;
      border-top: 1px solid #b3b9bb;
      border-bottom: 1px solid #b3b9bb;
      @include font-Montserrat_semiBold;
      @include pc {
        height: 26px;
      }
    }
    &Input {
      display: none;
    }
    &Fix {
      line-height: 1.1;
      min-width: 40px;
      text-align: right;
      font-size: 33px;
      color: #e60012;
      @include font-Montserrat_semiBold;
      @include pc {
        width: 40px;
      }
    }
    &Minus,
    &Plus {
      width: 35px;
      height: 35px;
      position: relative;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      @include pc {
        width: 26px;
        height: 26px;
      }
      &:before,
      &:after {
        content: '';
        width: 9px;
        height: 1px;
        display: block;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:before {}
      &:after {}
    }
    &Plus {
      background: #e60012;
      &:before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
}
