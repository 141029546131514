@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-logo {
  img {
    max-height: 20px;
    max-width: 100px;
    @include pc {
      max-height: 24px;
      max-width: 120px;
    }
  }
}
