@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-table {
  &__table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      padding: 10px 5px;
      vertical-align: middle;
      text-align: left;
      font-size: 12px;
      word-break: break-all;
      height: 100%;
    }
    th {
      font-weight: normal;
      width: 26%;
    }
    tr:nth-of-type(2n+1) th,
    tr:nth-of-type(2n+1) td {
      background: #ffe5e0;
    }
    tr:nth-of-type(2n+2) th,
    tr:nth-of-type(2n+2) td {
      background: #fff2ef;
    }
    tbody {
      td,
      th {
        text-align: left;
        &.-center {
          text-align: center;
        }
        &.-right {
          text-align: right;
        }
      }
    }
  }
  &__title {
    display: block;
    padding: 0 15px;
    word-break: break-all;
  }
  &__inner {
    padding: 0 15px;
    margin-left: -1px;
    word-break: break-all;
    height: 100%;
    border-left: 1px solid #a0a0a0;
  }
  &.-spec {
    th,
    td {
      font-size: 12px;
    }
    .c-table__title {
      white-space: nowrap;
    }
  }
  &.-static {
    margin: 30px 0;
    @include pc {
      margin: 40px 0;
    }
    tbody {
      border: 1px solid #bfbfbf;
    }
    tr {
      border-top: 1px solid #bfbfbf;
      &:first-of-type {
        border-top: none;
      }
    }
    th {
      vertical-align: top;
      width: 33%;
      @include pc {
        width: 20%;
      }
    }
    th,
    td {
      background: #fff !important;
      font-size: 12px;
      line-height: (22/12);
      padding: 12px 0;
      @include pc {
        font-size: 14px;
        padding: 15px 0;
      }
    }
    .c-table {
      &__title {
        @include pc {
          padding: 0 18px;
        }
      }
      &__inner {
        padding: 0 20px;
      }
    }
  }
  &.-scroll {
    margin-left: -5%;
    overflow: auto;
    padding: 0 5%;
    -webkit-overflow-scrolling: touch;
    @include pc {
      margin: 0;
      padding: 0;
    }
    thead {
      th {
        padding: 12px 1em;
        text-align: center;
        @include pc {
          padding: 15px 1em;
        }
      }
    }
    tbody {
      th {
        padding: 12px 15px;
        width: 20%;
        @include pc {
          padding: 15px 20px;
        }
      }
      td {}
    }
    tr {
      border-bottom: 1px solid #bfbfbf;
    }
    th {
      background: #f7f7f7 !important;
      border-right: 1px solid #bfbfbf;
      width: 13%;
      @include pc {
        @include vw(14);
      }
      .small {
        font-size: 12px;
      }
    }
    td {
      background: #fff !important;
      border-right: 1px solid #bfbfbf;
      @include pc {
        @include vw(14);
      }
    }
    .c-table {
      &__table {
        @include font-noto_sans;
        border-top: 1px solid #bfbfbf;
        border-left: 1px solid #bfbfbf;
        letter-spacing: 0.16em;
        @include sp {
          white-space: nowrap;
        }
      }
      &__title {
        padding: 0;
      }
      &__inner {
        border-left: none;
        padding: 0 8px;
      }
    }
  }
  &.-normal {
    margin: 0 -5%;
    padding: 0 5%;
    @include pc {
      margin: 0;
      padding: 0;
    }
    thead {
      th {
        padding: 12px 0;
        text-align: center;
        @include pc {
          padding: 15px 0;
        }
      }
    }
    tbody {
      th {
        padding: 12px 15px;
        width: 20%;
        white-space: nowrap;
        @include pc {
          padding: 15px 20px;
        }
      }
      td {}
    }
    tr {
      border-bottom: 1px solid #bfbfbf;
    }
    th {
      background: #f7f7f7 !important;
      border-right: 1px solid #bfbfbf;
      width: 13%;
      @include pc {
        @include vw(14);
      }
      .small {
        font-size: 12px;
      }
    }
    td {
      background: #fff !important;
      border-right: 1px solid #bfbfbf;
      @include pc {
        @include vw(14);
      }
    }
    .c-table {
      &__table {
        @include font-noto_sans;
        border-top: 1px solid #bfbfbf;
        border-left: 1px solid #bfbfbf;
        letter-spacing: 0.16em;
      }
      &__title {
        padding: 0;
      }
      &__inner {
        border-left: none;
        padding: 0 8px;
      }
    }
  }
  &__text {
    @include font-Montserrat_semiBold;
    color: #e60012;
    font-size: 11px;
    letter-spacing: 0.14em;
    margin-top: 12px;
    padding-right: 12px;
    position: relative;
    text-align: right;
    &:after {
      content: '';
      background: url(/img/common/icon_arrow05.svg)no-repeat;
      background-size: 100% auto;
      display: block;
      height: 6px;
      position: absolute;
      top: 50%;
      right: -2px;
      transform: translateY(-50%) rotate(-90deg);
      width: 10px;
    }
  }
}

.c-table {
  &__normal {
    border-collapse: collapse;
    width: 100%;
    caption {
      font-size: 16px;
      color: #e60012;
      text-align: left;
      margin-bottom: 10px;
    }
    th,
    td {
      font-size: 14px;
      padding: 10px 5px;
      vertical-align: middle;
      text-align: left;
      word-break: break-all;
      height: 100%;
      border-top: 1px solid #bfbfbf;
      border-bottom: 1px solid #bfbfbf;
      @include pc {
        font-size: 12px;
        padding: 12px 5px;
      }
    }
    th {
      font-weight: normal;
      width: 35%;
    }
    tbody {
      td,
      th {
        text-align: left;
        &.-center {
          text-align: center;
        }
        &.-right {
          text-align: right;
        }
      }
      th {
        border-left: 1px solid #bfbfbf;
      }
      td {
        border-right: 1px solid #bfbfbf;
      }
    }
    &Title {
      display: block;
      padding: 0 10px;
      word-break: break-all;
      @include pc {
        padding: 0 15px;
      }
    }
    &Inner {
      padding: 0 15px;
      margin-left: -1px;
      word-break: break-all;
      height: 100%;
      border-left: 1px solid #bfbfbf;
    }
  }
}